"use client";

import * as React from "react";
import {
  Column,
  ColumnDef,
  Header,
  SortingState,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from "@tanstack/react-table";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../shadcn/table";
import { cn } from "../../../lib/utils";

interface VenueOverviewDataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  data: TData[];
  rowClassName?: string;
  tableClassName?: string;
  cellClassName?: string;
  colsToPin?: string[];
}

export function VenueOverviewDataTable<TData, TValue>({
  columns,
  data,
  rowClassName,
  tableClassName,
  cellClassName,
  colsToPin,
}: VenueOverviewDataTableProps<TData, TValue>) {
  const getCommonPinningStyles = (
    column: Column<TData, unknown>
  ): { tailwind: string; css: React.CSSProperties } => {
    const isPinned = column.getIsPinned();
    const isLastLeftPinnedColumn =
      isPinned === "left" && column.getIsLastColumn("left");
    return {
      tailwind: isPinned
        ? `sticky z-30 opacity-95 bg-white ${isLastLeftPinnedColumn && "mr-4"}`
        : "relative",
      css: {
        left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
      },
    };

    // left: isPinned === "left" ? `${column.getStart("left")}px` : undefined,
    // opacity: isPinned ? 0.99 : 1,
    // position: isPinned ? "sticky" : "relative",
    // zIndex: isPinned ? 1 : 0,
  };

  const [sorting, setSorting] = React.useState<SortingState>([]);
  const [rowSelection, setRowSelection] = React.useState({});

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    manualPagination: true,
    onRowSelectionChange: setRowSelection,
    state: {
      sorting,
      rowSelection,
    },
  });

  React.useEffect(() => {
    if (colsToPin !== undefined) {
      table.getHeaderGroups().map((headerGroup) => {
        headerGroup.headers.map((header) => {
          if (colsToPin.some((colToPin) => colToPin === header.id)) {
            header.column.pin("left");
          }
        });
      });
    }
  }, []);

  return (
    <div className="flex flex-col">
      <Table className={cn("bg-white rounded-xl", tableClassName)}>
        <TableHeader>
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                const column = { header };
                return (
                  <TableHead
                    key={header.id}
                    className={cn(
                      "font-bold text-md whitespace-nowrap",
                      getCommonPinningStyles(column.header.column).tailwind
                    )}
                    style={{
                      ...getCommonPinningStyles(column.header.column).css,
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && "selected"}
                className={cn(
                  "border-b transition-colors hover:bg-neutral-90 data-[state=selected]:bg-neutral-60",
                  rowClassName
                )}
              >
                {row.getVisibleCells().map((cell) => {
                  const { column } = cell;
                  return (
                    <TableCell
                      key={cell.id}
                      className={cn(
                        cellClassName,
                        getCommonPinningStyles(column).tailwind
                      )}
                      style={{
                        ...getCommonPinningStyles(column).css,
                      }}
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            ))
          ) : (
            <TableRow>
              <TableCell
                colSpan={columns.length}
                className="h-24 text-center"
              >
                No results.
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
}
