import {
  Table as TableBase,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import React, { Fragment, useMemo } from "react";
import { Column, useTable } from "react-table";
import dayjs from "dayjs";
import { Checkbox } from "../../UI/shadcn/checkbox";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import ActionsCell from "../../UI/ActionCell";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EventBusyIcon from "@mui/icons-material/EventBusy";
import { ordinal } from "./ScheduleNew";
import Tooltip from "../../UI/Tooltip/Tooltip";
import Body1 from "../../UI/Text/Body/Body1";
import { GameStatus } from "@/src/types/types";
import TooltipMUI from "@mui/material/Tooltip";
import { Team, VenuesTreeQuery } from "../../../generated/graphql";
import { RegularGame } from "./ScheduleTypes";
import { TeamList } from "./ScheduleNew";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import Button from "../../UI/Button/Button";
import UndoIcon from "@mui/icons-material/Undo";

interface collapseVenueProps {
  teams: TeamList[];
  venues: VenuesTreeQuery["venuesTree"];
  handleChange: (
    index: number,
    column: keyof RegularGame,
    value: string
  ) => void;
  games: RegularGame[];
  isEdit: boolean;
  gameStatuses: GameStatus[];
  isPublishedSchedule: boolean;
  handleGameAction: (
    type: "Reschedule" | "Game Status" | "Score Standings",
    gameId: number
  ) => void;
  handleRemoveGame: (index: number) => void;
}

interface GameScheduleType extends RegularGame {
  isPastGame?: boolean;
}

export function matchupValueToId(
  value: number | null,
  type: number | null
): string | null {
  if (value == null) {
    return null;
  }
  if (type == 1) {
    return `${value} Place`;
  } else if (type == 2) {
    return `${value < 0 ? "Loser" : "Winner"} Game ${
      value < 0 ? value * -1 + (value * -1 - 1) : value + value - 2
    }`;
  } else if (type == 3) {
    return value.toString();
  }
  return null;
}

export function matchupValueToName(
  value: number | null,
  type: number | null
): string | null {
  if (value == null) {
    return null;
  }
  if (type == 1) {
    return `${ordinal(value + 1)} Place Place`;
  } else if (type == 2) {
    return `${value < 0 ? "Loser" : "Winner"} Game ${Math.abs(value)}`;
  } else if (type == 3) {
    return value.toString();
  }
  return null;
}

const hours = Array.from({ length: 24 }, (_, i) => i + 1);
const minutes = Array.from({ length: 60 / 5 }, (_, i) => i * 5);

const GameScheduleNew: React.FC<collapseVenueProps> = (props) => {
  const {
    teams,
    venues,
    games,
    isEdit,
    handleChange,
    gameStatuses,
    isPublishedSchedule,
    handleGameAction,
    handleRemoveGame,
  } = props;

  const options: {
    id: number;
    text: string;
    type: "Reschedule" | "Game Status" | "Score Standings";
  }[] = [
    { id: 0, text: "Reschedule", type: "Reschedule" },
    { id: 1, text: "Change Game Status", type: "Game Status" },
    { id: 2, text: "Adjust Score Standings", type: "Score Standings" },
  ];

  const activeTeams = teams.filter((team) => !team.inactive);

  const handleChangeRow = (
    index: number,
    column: keyof RegularGame,
    value: string
  ) => {
    handleChange(index, column, value);
  };
  const COLUMNS: Column<RegularGame>[] = [
    {
      Header: "GameId",
      id: "id",
      accessor: (d: RegularGame) => {
        const status: string =
          gameStatuses.find((status) => status.id == d.gameStatusId)?.name ||
          "";
        return (
          <TooltipMUI
            title={status}
            placement="top"
          >
            <div
              className="flex gap-2"
              style={{ width: "30px" }}
            >
              <p>{d.id}</p>
              {d.gameStatusId == 2 && <HistoryToggleOffIcon />}
              {d.gameStatusId == 3 && <EventBusyIcon />}
              {d.gameStatusId == 4 && <WarningAmberIcon />}
            </div>
          </TooltipMUI>
        );
      },
    },
    {
      Header: "Date",
      id: "date",
      accessor: (d: RegularGame) => {
        return (
          <div style={{ width: "100px" }}>
            {dayjs(d.startDateTimeLocal).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      Header: "Start Time",
      id: "startDateTimeLocal",
      accessor: (d: GameScheduleType, index: number) => {
        const date = new Date(d.startDateTimeLocal);
        const dateValue = dayjs(date);
        const hour = dateValue.hour();
        return (
          <div className="flex gap-1">
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                let newHour = value;
                handleChangeRow(
                  index,
                  "startDateTimeLocal",
                  dayjs(dateValue.hour(newHour)).format("YYYY-MM-DD HH:mm:ss")
                );
              }}
              name="hour"
              label="Hour"
              placeholder="hour"
              value={hour.toString()}
              disabled={d.isPastGame || d.isDeleted || false}
            >
              {hours.map((h) => ({ id: h.toString(), name: h }))}
            </FormFieldSelect>
            <div className="pt-5">:</div>
            <FormFieldSelect
              defaultValue="0"
              inputChange={(value) => {
                let newMinute = value;
                handleChangeRow(
                  index,
                  "startDateTimeLocal",
                  dayjs(dateValue.minute(newMinute)).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                );
              }}
              name="minutes"
              label="Minutes"
              placeholder="minutes"
              value={dateValue.minute().toString()}
              disabled={d.isPastGame || d.isDeleted || false}
            >
              {minutes.map((h) => ({ id: h.toString(), name: h }))}
            </FormFieldSelect>
          </div>
        );
      },
    },
    {
      Header: "Venue",
      id: "venue",
      accessor: (d: GameScheduleType, index: number) => {
        return (
          <div className="w-[400px] max-w-[400px]">
            <FormFieldSelect
              inputChange={(value) => {
                handleChangeRow(index, "venueId", value);
              }}
              placeholder="Venue"
              value={d.venueId.toString()}
              disabled={d.isPastGame || d.isDeleted || false}
            >
              {venues.map((venue) => {
                return {
                  id: venue.id,
                  name: venue.name,
                };
              })}
            </FormFieldSelect>
          </div>
        );
      },
    },
    {
      Header: "Matchup",
      id: "matchup",
      accessor: (d: GameScheduleType, index: number) => {
        return (
          <div className="flex flex-row items-center gap-2">
            <div>
              <FormFieldSelect
                inputChange={(value) => {
                  handleChangeRow(index, "homeTeamId", value);
                }}
                placeholder="Team"
                value={d.homeTeamId?.toString() || ""}
                disabled={d.isPastGame || d.isDeleted || false}
              >
                {d.isPastGame || d.isDeleted ? teams : activeTeams}
              </FormFieldSelect>
            </div>

            <Body1>VS</Body1>

            <div>
              <FormFieldSelect
                inputChange={(value) => {
                  handleChangeRow(index, "awayTeamId", value);
                }}
                placeholder="Team"
                value={d.awayTeamId?.toString() || ""}
                disabled={d.isPastGame || d.isDeleted || false}
              >
                {d.isPastGame || d.isDeleted ? teams : activeTeams}
              </FormFieldSelect>
            </div>
          </div>
        );
      },
    },
    {
      Header: "Player Visibility",
      id: "isVisible",
      accessor: (d: GameScheduleType, index: number) => {
        return (
          <div className="flex flex-row items-center justify-center">
            <Checkbox
              checked={
                d.isVisible == 1 || d.isVisible === undefined ? true : false
              }
              className={
                "hover:bg-secondary-opacity-08 flex h-[20px] w-[20px] min-w-[20px] min-h-[20px] border-2 border-neutral-20 appearance-none items-center justify-center rounded-sm bg-white outline-none focus:outline-none data-[state=checked]:bg-primary-50 data-[state=checked]:border-none"
              }
              onCheckedChange={(event) => {
                handleChangeRow(index, "isVisible", event ? "1" : "0");
              }}
              disabled={d.isPastGame || d.isDeleted || false}
            />
          </div>
        );
      },
    },
  ];

  if (isEdit) {
    COLUMNS.push({
      Header: "Actions",
      Cell: (d: any, index: number) => {
        return (
          <>
            {options && (
              <ActionsCell
                disabled={d.row.original.isDeleted}
                row={d.row}
                options={options}
                handleSelectedOption={(option, optionId) => {
                  const type = options[optionId].type;
                  handleGameAction(type, d.row.original.id);
                }}
              />
            )}
          </>
        );
      },
    });
  }

  COLUMNS.push({
    Header: "Remove",
    Cell: (d: any, index: number) => {
      return (
        <Button
          variant={d.row.original.isPastGame ? "disabled" : "secondary"}
          onClick={() => {
            handleRemoveGame(d.row.index);
          }}
          disabled={d.row.original.isPastGame}
        >
          {!d.row.original.isDeleted && <DeleteOutlineIcon />}
          {d.row.original.isDeleted && <UndoIcon />}
        </Button>
      );
    },
  });

  const columns = useMemo(
    () => COLUMNS,
    [JSON.stringify(games), JSON.stringify(teams), venues]
  );
  const data = useMemo(() => {
    return games.map((game) => {
      if (
        isPublishedSchedule &&
        game.startDateTimeLocal < new Date().toISOString()
      )
        return {
          ...game,
          isPastGame: true,
        };
      else return game;
    });
  }, [JSON.stringify(games), JSON.stringify(teams), venues]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns: columns,
      data: data,
    });

  return (
    <Fragment>
      <TableContainer
        sx={{
          bgcolor: "white",
          borderRadius: "12px",
          maxHeight: "100vh",
        }}
      >
        <TableBase
          stickyHeader
          aria-label="sticky table"
          sx={{
            maxHeight: "70vh",
          }}
          {...getTableProps()}
        >
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow
                {...headerGroup.getHeaderGroupProps()}
                sx={{ zIndex: 1 }}
              >
                {headerGroup.headers.map((column) => (
                  <TableCell
                    sx={{
                      bgcolor: "white",
                      color: "var(--textColor)",
                      fontSize: "16px",
                      textAlign: "left",
                      fontWeight: 550,
                      borderColor: "var(--table-border)",
                      zIndex: "inherit",
                      padding: "10px",
                    }}
                    {...column.getHeaderProps()}
                  >
                    <div>{column.render("Header")}</div>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <TableRow
                  hover
                  sx={{
                    borderBottom: "5px",
                    borderWidth: "2px",
                  }}
                  role="checkbox"
                  tabIndex={-1}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        {...cell.getCellProps()}
                        sx={{
                          textAlign: "left",
                          fontSize: "15px",
                          color: "var(--textColor)",
                          // borderWidth: '2px',
                          borderColor: "var(--table-border)",
                          paddingBottom: "5px",
                          paddingTop: "5px",
                        }}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </TableBase>
      </TableContainer>
    </Fragment>
  );
};

export default GameScheduleNew;
