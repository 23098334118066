import React, { useEffect } from "react";
import Button from "../../UI/Button/Button";
import {
  blankVenueOverviewCreateGameslotForm,
  initialVenueOverviewCreateGameslotForm,
  useVenueOverviewContext,
} from "../../../context/VenueOverviewContext";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import { DatePicker } from "../../UI/shadcn/Time/date-picker";
import { dateWithoutTimezone } from "../../../utils/timeFunctions";
import { Card } from "../../UI/shadcn/card";
import { DateTimePicker } from "../../UI/shadcn/Time/date-time-picker";
import {
  useGameslotRelationalLazyQuery,
  useGameslotUpdateMutation,
} from "../../../generated/graphql";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../app/store";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import dayjs from "dayjs";

const VenueOverviewUpdateGameslot = () => {
  const dispatch = useDispatch<AppDispatch>();

  const {
    activeVenueOverviewRow,
    filter,
    showUpdateGameslotModal,
    setShowUpdateGameslotModal,
    showCreateGameslotModal,
    setShowCreateGameslotModal,
    venueOverviewCreateGameslotForm,
    setVenueOverviewCreateGameslotForm,
    venueOverviewCreateGameslotFormErrors,
    venueOverviewUpdateGameslotData,
    setVenueOverviewUpdateGameslotData,
    venueOverviewCreateGameslotFormPreview,
    setVenueOverviewData,
  } = useVenueOverviewContext();

  const [UpdateGameslot, { data, loading }] = useGameslotUpdateMutation();
  const [GameslotQuery, { loading: loadingGameslots }] =
    useGameslotRelationalLazyQuery();

  async function handleUpdate() {
    if (venueOverviewCreateGameslotFormErrors.length > 0) {
      dispatch(displayAlertError("Cannot create gameslot with errors"));
      return;
    }
    if (!venueOverviewUpdateGameslotData) {
      dispatch(displayAlertError("No gameslot selected to update"));
      return;
    }

    await UpdateGameslot({
      variables: {
        updateGameslotInput: {
          id: venueOverviewUpdateGameslotData.id,
          gameslotInput: venueOverviewCreateGameslotFormPreview[0],
        },
      },
      onCompleted: async (data) => {
        if (data.gameslotUpdate.success) {
          dispatch(displayAlertSuccess(data.gameslotUpdate.message));
          await GameslotQuery({
            fetchPolicy: "no-cache",
            variables: {
              gameslotVenueOverviewFilters: {
                venueId: filter.venueId,
                startDate: dateWithoutTimezone(filter.startDate),
                endDate: dateWithoutTimezone(filter.endDate),
              },
            },
            onCompleted: (data) => {
              setVenueOverviewData((prevState) => ({
                ...prevState,
                currentGameslots: data.gameslotRelational,
              }));
              setShowUpdateGameslotModal(false);
              setVenueOverviewUpdateGameslotData(undefined);
              setVenueOverviewCreateGameslotForm(
                blankVenueOverviewCreateGameslotForm
              );
            },
          });
        } else {
          dispatch(displayAlertError(data.gameslotUpdate.message));
        }
      },
      onError: (error) => {
        dispatch(displayAlertError(error.message || "Something went wrong"));
      },
    });
  }

  return (
    <Card className="sticky z-50 w-full bottom-4 rounded-2xl">
      <LoadingDialog open={loading} />
      <div className="flex flex-row items-end justify-between gap-4 p-2 flex-nowrap">
        <div className="flex-grow max-w-full min-w-0">
          <FormFieldViewOnly
            label="Venue"
            text={venueOverviewCreateGameslotForm.venue.name}
            className="max-w-full"
          />
        </div>
        <div className="flex-shrink">
          <DateTimePicker
            className="w-full"
            label="Start From Date"
            date={new Date(venueOverviewCreateGameslotForm.startDateTimeLocal)}
            setDate={(date) => {
              console.log(date);
              if (date) {
                console.log(dateWithoutTimezone(date));
                setVenueOverviewCreateGameslotForm((prevState) => ({
                  ...prevState,
                  startDateTimeLocal:
                    dayjs(date).date() !==
                    dayjs(prevState.startDateTimeLocal).date()
                      ? dayjs(prevState.startDateTimeLocal)
                          .set("date", date.getDate())
                          .format("YYYY-MM-DD HH:mm:ss")
                      : dateWithoutTimezone(date),
                }));
              }
            }}
          />
        </div>
        <div className="flex-shrink">
          <FormFieldSelect
            name="duration"
            label="Duration"
            placeholder="Duration"
            value={venueOverviewCreateGameslotForm.duration.toString() ?? "0"}
            inputChange={(value: string) => {
              setVenueOverviewCreateGameslotForm((prevState) => ({
                ...prevState,
                duration: +value,
              }));
            }}
          >
            {Array.from(Array(12), (_, i) => {
              return {
                id: (i + 1) * 15,
                name: ((i + 1) * 15).toString(),
              };
            })}
          </FormFieldSelect>
        </div>
        {/* <div className="max-w-60 min-w-32">
          <FormFieldSelect
            name="numberOfGameslots"
            label="# of Gameslots"
            placeholder="# of Gameslots"
            value={
              venueOverviewCreateGameslotForm.numberOfGameslots.toString() ??
              "0"
            }
            inputChange={(value: string) => {
              setVenueOverviewCreateGameslotForm((prevState) => ({
                ...prevState,
                numberOfGameslots: +value,
              }));
            }}
          >
            {Array.from(Array(12), (_, i) => {
              return {
                id: i + 1,
                name: (i + 1).toString(),
              };
            })}
          </FormFieldSelect>
        </div>
        <div className="max-w-60 min-w-32">
          <FormFieldSelect
            name="weeks"
            label="weeks"
            placeholder="weeks"
            value={venueOverviewCreateGameslotForm.weeks.toString() ?? "0"}
            inputChange={(value: string) => {
              setVenueOverviewCreateGameslotForm((prevState) => ({
                ...prevState,
                weeks: +value,
              }));
            }}
          >
            {Array.from(
              Array(activeVenueOverviewRow?.contractItems.length ?? 0),
              (_, i) => {
                return {
                  id: i + 1,
                  name: (i + 1).toString(),
                };
              }
            )}
          </FormFieldSelect>
        </div> */}
        <Button
          className="h-fit"
          variant="negative"
          onClick={() => {
            setShowUpdateGameslotModal(false);
            setVenueOverviewCreateGameslotForm(
              blankVenueOverviewCreateGameslotForm
            );
            setVenueOverviewUpdateGameslotData(undefined);
          }}
        >
          Cancel
        </Button>
        <Button
          className="h-fit"
          disabled={venueOverviewCreateGameslotFormErrors.length > 0}
          variant={
            venueOverviewCreateGameslotFormErrors.length > 0
              ? "disabled"
              : "primary"
          }
          onClick={() => {
            handleUpdate();
          }}
        >
          Update
        </Button>
      </div>
    </Card>
  );
};

export default VenueOverviewUpdateGameslot;
