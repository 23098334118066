import { z } from "zod";

export type TeamPlayoffMatchupCategory = {
  id: string;
  value: number;
  type: number;
  name: string;
};

export type DetailedTeamScoreRecord = {
  scoreRecord: TeamScoreRecord;
  teamPointsRecord: TeamPointsTotal;
  gamesPlayed: number;
  teamId: number;
  teamName: string;
};

export type TeamScoreRecord = {
  wins: number;
  losses: number;
  ties: number;
  defaultLosses: number;
};

export type TeamPointsTotal = {
  pointsFor: number;
  pointsAgainst: number;
  pointsTotal: number;
  pointsDifference: number;
};

export type RegularGame = z.infer<typeof RegularGameSchema>;
export type PlayoffGame = z.infer<typeof PlayoffGameSchema>;
export type ScheduleDetails = z.infer<typeof ScheduleDetailsSchema>;
export type Schedule = z.infer<typeof ScheduleSchema>;

export type PlacementToTeam = {
  teamId: number | null;
  placement: number;
  name: string;
};

export type GameForCalculatingStandings = {
  id: number;
  homeTeamId: number;
  awayTeamId: number;
  homeScore: number | null;
  awayScore: number | null;
  isWinByDefault: boolean;
  countHomeScore: boolean;
  countAwayScore: boolean;
};

const RegularGameSchema = z
  .object({
    id: z.number().optional(),
    startDateTimeLocal: z.string(),
    venueId: z.number(),
    homeTeamId: z.number().nullable(),
    awayTeamId: z.number().nullable(),
    gameTypeId: z.number(),
    isVisible: z.number(),
    week: z.number(),
    gameStatusId: z.number(),
    countHomeScore: z.boolean(),
    countAwayScore: z.boolean(),
    isDeleted: z.boolean().optional(),
    isChampGame: z.number(),
  })
  .refine(
    (data) => {
      return data.awayTeamId !== data.homeTeamId;
    },
    {
      message: "Home and Away teams cannot be the same",
      path: ["awayTeamId", "homeTeamId"],
    }
  )
  .refine(
    (data) => data.homeTeamId !== undefined && data.awayTeamId !== undefined,
    {
      message: "Home and Away teams are required for regular season games.",
      path: ["homeTeamId", "awayTeamId"],
    }
  );
const PlayoffGameSchema = z
  .object({
    id: z.number().optional(),
    startDateTimeLocal: z.string(),
    venueId: z.number(),
    homeTeamId: z.number().optional(),
    awayTeamId: z.number().optional(),
    gameTypeId: z.number(),
    isVisible: z.number(),
    week: z.number(),
    gameStatusId: z.number(),
    countHomeScore: z.boolean(),
    countAwayScore: z.boolean(),
    homeTeamType: z.number().optional(),
    homeTeamValue: z.number().optional(),
    awayTeamType: z.number().optional(),
    awayTeamValue: z.number().optional(),
    playoffGameNumber: z.number().optional(),
    isDeleted: z.boolean().optional(),
    isChampGame: z.number(),
  })
  .refine(
    (data) => {
      if (data.homeTeamId === undefined || data.awayTeamId === undefined) {
        return true;
      } else if (data.homeTeamId && data.awayTeamId) {
        return data.homeTeamId !== data.awayTeamId;
      }
      return false;
    },
    {
      message: "Playoff Home and Away teams cannot be the same",
      path: ["awayTeamId", "homeTeamId"],
    }
  );

const ScheduleDetailsSchema = z.object({
  divisionId: z.number(),
  scheduleStatusId: z.number(),
  lastModifiedAt: z.date(),
});

const ScheduleSchema = z.object({
  currentScheduleId: z.number().optional(),
  scheduleDetails: ScheduleDetailsSchema,
  regularGames: z.array(RegularGameSchema),
  playoffGames: z.array(PlayoffGameSchema),
});

const initialRegularGame: RegularGame = {
  id: undefined,
  startDateTimeLocal: "",
  venueId: 0,
  homeTeamId: null,
  awayTeamId: null,
  gameTypeId: 0,
  isVisible: 0,
  week: 0,
  gameStatusId: 0,
  countHomeScore: false,
  countAwayScore: false,
  isChampGame: 0,
};
const initialPlayoffGame: PlayoffGame = {
  id: undefined,
  startDateTimeLocal: "",
  venueId: 0,
  homeTeamId: undefined,
  awayTeamId: undefined,
  gameTypeId: 0,
  isVisible: 0,
  week: 0,
  gameStatusId: 0,
  countHomeScore: false,
  countAwayScore: false,
  homeTeamType: undefined,
  homeTeamValue: undefined,
  awayTeamType: undefined,
  awayTeamValue: undefined,
  playoffGameNumber: undefined,
  isChampGame: 0,
};
const initialScheduleDetails: ScheduleDetails = {
  divisionId: 0,
  scheduleStatusId: 0,
  lastModifiedAt: new Date(),
};

const initialSchedule: Schedule = {
  currentScheduleId: undefined,
  scheduleDetails: initialScheduleDetails,
  regularGames: [],
  playoffGames: [],
};

export type PlayoffGameNumber = {
  id: number;
  name: string;
};

export {
  RegularGameSchema,
  PlayoffGameSchema,
  ScheduleDetailsSchema,
  ScheduleSchema,
  initialRegularGame,
  initialPlayoffGame,
  initialScheduleDetails,
  initialSchedule,
};
