import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../app/store";
import { logout, logoutUser, removeUserToLocalStorage } from "../app/authSlice";

const LogoutUser = () => {
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    dispatch(logout());
    removeUserToLocalStorage();
    window.location.href = "/login";
    // dispatch(logoutUser());
  }, [dispatch]);

  return (
    <>
      <h3>You have been logged out successfully!</h3>
    </>
  );
};

export default LogoutUser;
