import { gql } from "@apollo/client";

const GET_REGIONS = gql`
  query Regions {
    regions {
      abbreviation
      id
      name
    }
  }
`;

const GET_SPORTS = gql`
  query Sports {
    sports {
      id
      name
      scoringType {
        id
        name
      }
    }
  }
`;

const GET_SPORTS_LIST = gql`
  query SportsList {
    sports {
      id
      name
    }
  }
`;

const GET_SPORTS_BY_USERID = gql`
  query SportsByUserId($userId: String!) {
    sportsByUserId(userId: $userId) {
      id
      name
    }
  }
`;

const GET_TIE_BREAK_POLICIES = gql`
  query TieBreakPolicy {
    tieBreakPolicies {
      id
      name
    }
  }
`;

const GET_STANDING_POLICIES = gql`
  query StandingsPolicies {
    standingsPolicies {
      id
      name
    }
  }
`;

const GET_GENDER_FORMAT = gql`
  query GenderFormats {
    genderFormats {
      id
      name
    }
  }
`;

const GET_VENUE_TYPE = gql`
  query VenueTypes {
    venueTypes {
      id
      name
    }
  }
`;

const GET_STAFF_TYPE = gql`
  query StaffTypes($regions: [Int]) {
    staffTypes(regions: $regions) {
      id
      baseRate
      name
      regionId
      isContractor
    }
  }
`;

const GET_LEAGUE_TYPES = gql`
  query LeagueTypes {
    leagueTypes {
      id
      name
    }
  }
`;

const GET_OFFICIATED_STATUSES = gql`
  query OfficiatedStatuses {
    officiatedStatuses {
      id
      name
    }
  }
`;

const GET_SPORTS_FORMAT = gql`
  query SportFormats(
    $sportFormatFilters: SportFormatFilters
    $page: Int
    $pageSize: Int
  ) {
    sportFormats(
      sportFormatFilters: $sportFormatFilters
      page: $page
      pageSize: $pageSize
    ) {
      sportsFormat {
        genderFormat {
          name
        }
        id
        leagueType {
          name
        }
        region {
          name
        }
        sport {
          name
        }
        name
        officiatedStatus {
          name
        }
        venueType {
          id
          name
        }
        peopleOnField
        minWomen
        minMen
        maxRosterSizeTeam
      }
      count
    }
  }
`;

const CREATE_SPORT_FORMAT = gql`
  mutation SportFormatCreate($sportFormatArgs: CreateSportFormatInput!) {
    sportFormatCreate(sportFormatArgs: $sportFormatArgs) {
      message
      code
      success
    }
  }
`;

const GET_SPORT_FORMAT_BY_ID = gql`
  query SportFormat($sportFormatId: ID!) {
    sportFormat(id: $sportFormatId) {
      abbreviation
      defaultEmail
      genderFormat {
        id
      }
      id
      weeksOfPlayOffs
      venueType {
        id
      }
      venueSize {
        id
      }
      ruleId
      tieBreakPolicy {
        id
      }
      standingsPolicy {
        id
      }
      sport {
        id
      }
      maxRosterSizeIndy
      peopleOnField
      officiatedStatus {
        id
      }
      region {
        id
      }
      name
      minWomenIndy
      minWomen
      minMenIndy
      minMen
      maxRosterSizeTeam
      leagueType {
        id
      }
      staffRequired {
        count
        staffTypeId
      }
      scoringFormatId
    }
  }
`;

const UPDATE_SPORT_FORMAT = gql`
  mutation SportFormatUpdate($sportFormatArgs: UpdateSportFormatInput!) {
    sportFormatUpdate(sportFormatArgs: $sportFormatArgs) {
      code
      message
      success
    }
  }
`;

const CREATE_LEAGUE = gql`
  mutation LeagueCreate($leagueArgs: CreateLeagueInput!) {
    leagueCreate(leagueArgs: $leagueArgs) {
      code
      message
      success
    }
  }
`;

const UPDATE_LEAGUE = gql`
  mutation LeagueUpdate($leagueArgs: UpdateLeagueInput!) {
    leagueUpdate(leagueArgs: $leagueArgs) {
      code
      message
      success
    }
  }
`;

const GET_LEAGUES = gql`
  query Leagues($leagueFilters: LeagueFilters) {
    leagues(leagueFilters: $leagueFilters) {
      divisionName
      gameDuration
      gameTimeslot
      gamesPerOccurrence
      id
      name
      sportFormat {
        name
        weeksOfPlayOffs
        region {
          id
          name
        }
        genderFormat {
          id
          name
        }
        sport {
          id
          name
        }
      }
      venues {
        name
        id
      }
    }
  }
`;

const GET_LEAGUE_BY_ID = gql`
  query League($leagueId: Int!) {
    league(id: $leagueId) {
      divisionName
      gameDuration
      gameTimeslot
      gamesPerOccurrence
      id
      leagueNotes
      name
      sportFormat {
        name
        id
        region {
          id
        }
        sport {
          id
        }
      }
      venues {
        id
        name
      }
      imageLink
    }
  }
`;

const GET_VENUES = gql`
  query Venues($venueFilters: VenueFilters) {
    venues(venueFilters: $venueFilters) {
      id
      name
    }
  }
`;

const GET_VENUE_SIZES = gql`
  query VenueSizes {
    venueSizes {
      id
      name
    }
  }
`;

const CREATE_REGISTRATION_BATCH = gql`
  mutation RegistrationBatchCreate(
    $registrationBatchArgs: CreateRegistrationBatchInput!
  ) {
    registrationBatchCreate(registrationBatchArgs: $registrationBatchArgs) {
      code
      message
      success
    }
  }
`;

const UPDATE_REGISTRATION_BATCH = gql`
  mutation RegistrationBatchUpdate(
    $registrationBatchArgs: UpdateRegistrationBatchInput!
  ) {
    registrationBatchUpdate(registrationBatchArgs: $registrationBatchArgs) {
      code
      message
      success
    }
  }
`;

const DELETE_REGISTRATION_BATCH = gql`
  mutation RegistrationBatchDelete($registrationBatchDeleteId: Int!) {
    registrationBatchDelete(id: $registrationBatchDeleteId) {
      code
      message
      success
    }
  }
`;

const GET_REGISTRATION_BATCH = gql`
  query RegistrationBatches($page: Int, $pageSize: Int) {
    registrationBatches(page: $page, pageSize: $pageSize) {
      registrationBatches {
        id
        name
        regCloseDate
        regOpenDate
        displayPrice
        displaySession
      }
      count
    }
  }
`;

const GET_REGISTRATION_BATCH_BY_ID = gql`
  query RegistrationBatch($registrationBatchId: Int!) {
    registrationBatch(id: $registrationBatchId) {
      id
      name
      regCloseDate
      regOpenDate
      displayPrice
      displaySession
    }
  }
`;

const GET_REGISTRATION_REPORT = gql`
  query RegistrationReport(
    $registrationReportInput: RegistrationReportInput!
    $page: Int
    $pageSize: Int
  ) {
    registrationReport(
      registrationReportInput: $registrationReportInput
      page: $page
      pageSize: $pageSize
    ) {
      id
      registrationType {
        type
      }
      session {
        league {
          name
          sportFormat {
            region {
              name
            }
          }
        }
        dayOfWeek
        registrationBatch {
          name
        }
      }
      shoppingCartItem {
        amountPaid
      }
      team {
        name
      }
      createdAt
      user {
        id
        email
        firstName
        genderIdentity {
          name
        }
        lastName
      }
      shoppingCart {
        id
      }
    }
  }
`;

const GET_REGISTRATION_REPORT_COUNT = gql`
  query RegistrationReportCount(
    $registrationReportInput: RegistrationReportInput!
  ) {
    registrationReportCount(registrationReportInput: $registrationReportInput)
  }
`;

const GET_REGISTRATION_TYPES = gql`
  query RegistrationTypes {
    registrationTypes {
      id
      type
    }
  }
`;

const CREATE_SESSIONS = gql`
  mutation SessionCreate($sessionArgs: CreateSessionInput!) {
    sessionCreate(sessionArgs: $sessionArgs) {
      code
      message
      success
    }
  }
`;

const UPDATE_SESSIONS = gql`
  mutation SessionUpdate($sessionArgs: UpdateSessionInput!) {
    sessionUpdate(sessionArgs: $sessionArgs) {
      code
      message
      success
    }
  }
`;

const GET_SESSIONS = gql`
  query Sessions($sessionFilters: SessionFilters) {
    sessions(sessionFilters: $sessionFilters) {
      setCapacity
      dayOfWeek
      endDate
      exceptionDates
      id
      indyTeams
      league {
        name
        sportFormat {
          sport {
            name
            id
          }
          region {
            name
          }
        }
      }
      maxCapacity
      numberOfWeeks
      priceForIndy
      priceForTeam
      registrationBatch {
        name
      }
      sessionNotes
      startDate
      startTimes
      weeksOfPlayoffs
    }
  }
`;

const GET_SESSION_BY_DAY_AND_LEAGUE_SEARCH = gql`
  query getSessionByDayAndLeagueSearch(
    $dayOfWeek: Int!
    $leagueSearch: String!
    $regions: [Int]!
    $userId: String!
  ) {
    getSessionByDayAndLeagueSearch(
      dayOfWeek: $dayOfWeek
      leagueSearch: $leagueSearch
      regions: $regions
      userId: $userId
    ) {
      id
      dayOfWeek
      startDate
      endDate
      startTimes
      setCapacity
      maxCapacity
      priceForTeam
      priceForIndy
      indyTeams
      numberOfWeeks
      weeksOfPlayoffs
      exceptionDates
      sessionNotes
      registrationBatch {
        id
        name
        regOpenDate
        regCloseDate
      }
      league {
        id
        name
        sportFormat {
          sport {
            name
          }
          region {
            id
            name
            taxRate
            txnFee {
              id
              productTypeId
              amount
            }
          }
        }
      }
      availability {
        freeAgent
        team
      }
    }
  }
`;

const GET_WAITLISTED_SESSIONS_BY_DAY_AND_LEAGUE_SEARCH = gql`
  query getWaitlistSessionsByDayAndLeagueSearch(
    $dayOfWeek: Int!
    $regions: [Int]!
    $userId: String!
    $leagueSearch: String!
  ) {
    getWaitlistSessionsByDayAndLeagueSearch(
      dayOfWeek: $dayOfWeek
      regions: $regions
      userId: $userId
      leagueSearch: $leagueSearch
    ) {
      id
      dayOfWeek
      startDate
      endDate
      startTimes
      setCapacity
      maxCapacity
      priceForTeam
      priceForIndy
      indyTeams
      numberOfWeeks
      weeksOfPlayoffs
      exceptionDates
      sessionNotes
      registrationBatch {
        id
        name
        regOpenDate
        regCloseDate
      }
      league {
        id
        name
        sportFormat {
          sport {
            name
          }
          region {
            id
            name
            taxRate
            txnFee {
              id
              productTypeId
              amount
            }
          }
        }
      }
      availability {
        freeAgent
        team
      }
    }
  }
`;

export {
  GET_REGIONS,
  GET_SPORTS,
  GET_TIE_BREAK_POLICIES,
  GET_STANDING_POLICIES,
  GET_LEAGUE_TYPES,
  GET_GENDER_FORMAT,
  GET_STAFF_TYPE,
  GET_VENUE_TYPE,
  GET_OFFICIATED_STATUSES,
  CREATE_SPORT_FORMAT,
  GET_SPORTS_FORMAT,
  UPDATE_SPORT_FORMAT,
  GET_SPORT_FORMAT_BY_ID,
  CREATE_LEAGUE,
  UPDATE_LEAGUE,
  GET_VENUES,
  GET_VENUE_SIZES,
  GET_LEAGUES,
  GET_REGISTRATION_BATCH,
  CREATE_REGISTRATION_BATCH,
  UPDATE_REGISTRATION_BATCH,
  CREATE_SESSIONS,
  UPDATE_SESSIONS,
  GET_SESSIONS,
  GET_REGISTRATION_BATCH_BY_ID,
  DELETE_REGISTRATION_BATCH,
  GET_LEAGUE_BY_ID,
  GET_SESSION_BY_DAY_AND_LEAGUE_SEARCH,
  GET_REGISTRATION_REPORT,
  GET_REGISTRATION_REPORT_COUNT,
  GET_REGISTRATION_TYPES,
  GET_SPORTS_LIST,
  GET_SPORTS_BY_USERID,
  GET_WAITLISTED_SESSIONS_BY_DAY_AND_LEAGUE_SEARCH,
};
