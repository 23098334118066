import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import { RootState } from "./store";
import {
  Location,
  RequestedShifts,
  ShiftNote,
  ShiftReportQuestions,
  ShiftReport,
  ShiftStatus,
  StaffType,
  User,
  UserAccount,
  Venue,
  Shift,
  ShiftPaymentStatus,
} from "../types/types";
import { client } from "../graphql";
import { GET_STAFF_TYPE } from "../graphql/queries";
import { GET_THIRD_PARTY_CONTRACTORS } from "../graphql/queries/thirdPartyContractors";
import {
  ASSIGN_SHIFT,
  CREATE_SHIFT,
  DELETE_SHIFT,
  GET_DATE_GROUPED_SHIFTS,
  GET_OPEN_SHIFTS,
  GET_SHIFTS_NOT_PAID,
  GET_SHIFT_PAYMENT_STATUSES,
  MY_SHIFT,
  UPDATE_SHIFT,
  UPDATE_SHIFTS_PAID,
  UPDATE_SHIFT_STATUS,
} from "../graphql/queries/shifts";
import {
  CREATE_REQUESTED_SHIFT,
  DELETE_REQUESTED_SHIFT,
  GET_APPLIED_SHIFTS,
  GET_REQUESTED_SHIFTS,
} from "../graphql/queries/requestedShift";
import { CREATE_SHIFT_REPORT } from "../graphql/queries/shiftReport";
import {
  CREATE_SHIFT_NOTE,
  GET_SHIFT_NOTES_BY_SHIFTID,
} from "../graphql/queries/shiftNote";
import { GET_SHIFT_STATUS } from "../graphql/queries/shiftStatus";
import { GET_SHIFT_REPORT_QUESTIONS } from "../graphql/queries/shiftReportQuestions";
import { GET_LOCATIONS } from "../graphql/queries/location";
import { GET_STAFF_MEMBERS } from "../graphql/queries/user";
import axios from "axios";
import { GET_VENUES_TREE } from "../graphql/queries/venues";
import { toast as toastStackable } from "sonner";

// const jwtToken = localStorage.getItem('jwtToken');
// const user = localStorage.getItem('user');
// const userLocation = localStorage.getItem('userLocation');

type AsyncThunkConfig = {
  state: RootState;
};

interface ShiftFilter {
  toDate: string;
  fromDate: string;
  assignedTo: string;
  weekday: number;
  isReported: string;
  shiftStatus: string;
}

interface OpenShiftFilter {
  toDate: string;
  fromDate: string;
  location: number;
  staffType: number;
}

interface RequestedShiftFilter {
  toDate: string;
  fromDate: string;
  venueId: number;
}

interface ThirdPatyContractorFilter {
  toDate: string;
  fromDate: string;
  contractorId: number;
  weekday: number;
}

interface InitialStateShift {
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  message: string;
  locationsOptions: Location[];
  staffTypeOptions: StaffType[];
  staffMembersOptions: any;
  emailOptions: any;
  shiftStatusOptions: ShiftStatus[];
  shiftReportStatusOptions: ShiftReport[];
  shifts: any;
  openShifts: any;
  myShiftsHistory: any;
  myShiftsUpcoming: any;
  requestedShifts: RequestedShifts[];
  appliedShifts: any;
  shiftFilters: ShiftFilter;
  openShiftFilters: OpenShiftFilter;
  requestedShiftFilters: RequestedShiftFilter;
  shiftNoteId: number;
  notes: ShiftNote[];
  shiftReportQuestions: ShiftReportQuestions[];
  shiftsFinance: any;
  thirdPartyContractorOptions: any;
  thirdPartyContractorFilters: ThirdPatyContractorFilter;
  venues: Venue[];
  shiftPaymentStatuses: ShiftPaymentStatus[];
}

const initialState: InitialStateShift = {
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
  locationsOptions: [],
  staffTypeOptions: [],
  staffMembersOptions: [],
  emailOptions: [],
  shiftStatusOptions: [],
  shiftReportStatusOptions: [],
  shifts: [],
  openShifts: [],
  myShiftsHistory: [],
  myShiftsUpcoming: [],
  requestedShifts: [],
  appliedShifts: [],
  shiftFilters: {
    toDate: moment().add(7, "days").format("YYYY-MM-DD"),
    fromDate: moment().format("YYYY-MM-DD"),
    assignedTo: "0",
    weekday: 7,
    isReported: "0",
    shiftStatus: "0",
  },
  openShiftFilters: {
    toDate: moment().add(30, "days").format("YYYY-MM-DD"),
    fromDate: moment().format("YYYY-MM-DD"),
    location: 0,
    staffType: 0,
  },
  requestedShiftFilters: {
    toDate: moment().add(30, "days").format("YYYY-MM-DD"),
    fromDate: moment().format("YYYY-MM-DD"),
    venueId: 0,
  },
  shiftNoteId: 0,
  notes: [],
  shiftReportQuestions: [],
  shiftsFinance: [],
  thirdPartyContractorOptions: [],
  thirdPartyContractorFilters: {
    toDate: moment().add(30, "days").format("YYYY-MM-DD"),
    fromDate: moment().format("YYYY-MM-DD"),
    contractorId: 0,
    weekday: 7,
  },
  venues: [],
  shiftPaymentStatuses: [],
};

// instance for requests
let baseURL = `${
  process.env.REACT_APP_ENV === "production"
    ? "https://admin.jamsports.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://admin.jamitall.com"
    : "http://localhost:3001"
}/api/v1`;

// Get Locations
export const getAllLocations = createAsyncThunk(
  "shift/getAllLocations",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_LOCATIONS,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venues = response.data.locations;
      return venues;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Shift Payment Statuses
export const getShiftPaymentStatuses = createAsyncThunk(
  "shift/getShiftPaymentStatuses",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_SHIFT_PAYMENT_STATUSES,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      console.log("🚀🚀🚀🚀 ~ response:", response);

      const shiftPaymentStatuses = response.data.shiftPaymentStatuses;
      return shiftPaymentStatuses;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Venues Tree
export const getVenuesTree = createAsyncThunk(
  "shift/getVenuesTree",
  async (args: any, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_VENUES_TREE,
        variables: args,
        fetchPolicy: "network-only",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const venues = response.data.venuesTree;
      return venues;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

// Get Shift Status/Attendance Type
export const getShiftStatus = createAsyncThunk(
  "shift/getShiftStatus",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_SHIFT_STATUS,
        fetchPolicy: "network-only",
      });

      return await response.data.shiftStatuses;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Update Shift Status/Attendance Type
export const updateShiftStatus = createAsyncThunk(
  "shift/updateShiftStatus",
  async (shiftStatusArgs: any, thunkAPI) => {
    const { shiftId, shiftStatusId } = shiftStatusArgs;

    try {
      const response = await client.mutate({
        mutation: UPDATE_SHIFT_STATUS,
        variables: {
          shiftId,
          shiftStatusId,
        },
      });
      return response.data.shiftStatusUpdate.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Staff Type Management: Get Staff Type
export const getStaffType = createAsyncThunk(
  "shift/getStaffType",
  async (_, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const regionsId = state.venueMaster.selectedRegions.map(
        (region: any) => +region.id
      );
      const response = await client.query({
        query: GET_STAFF_TYPE,
        variables: {
          regions: regionsId,
        },
        fetchPolicy: "network-only",
      });
      const staffType = response.data.staffTypes;
      return await staffType;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Staff Type Management: Get Staff Type By region
export const getStaffTypeByRegion = createAsyncThunk(
  "shift/getStaffTypeByRegion",
  async (regionId: number, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_STAFF_TYPE,
        variables: {
          regions: [regionId],
        },
        fetchPolicy: "network-only",
      });
      const staffType = response.data.staffTypes;
      return await staffType;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Staff members
export const getAllStaffMembers = createAsyncThunk(
  "shift/getAllStaffMembers",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_STAFF_MEMBERS,
        fetchPolicy: "network-only",
      });
      const staffMembers = response.data.staffMembers.map(
        (
          // members: IUser
          members: User
        ) => ({
          id: members.id,
          name: `${members.firstName} ${members.lastName}`,
        })
      );
      return staffMembers;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Shifts
export const getAllShifts = createAsyncThunk(
  "shift/getAllShifts",
  async (filterArgs: ShiftFilter, thunkAPI) => {
    // query dates in the url: always toDate and fromDate
    // to date Present Date
    // from date a week before initially
    const { toDate, fromDate, assignedTo, weekday, isReported, shiftStatus } =
      filterArgs;
    const state: any = thunkAPI.getState();
    const regionsId = state.venueMaster.selectedRegions.map(
      (region: any) => +region.id
    );
    try {
      const response: any = await client.query({
        query: GET_DATE_GROUPED_SHIFTS,
        variables: {
          shiftFilter: {
            assignedTo: assignedTo,
            fromDate: fromDate,
            toDate: toDate,
            isReported: isReported,
            weekday: weekday === 7 ? null : weekday,
            regions: regionsId,
            shiftStatus: shiftStatus || "0",
          },
        },
        fetchPolicy: "network-only",
      });

      let shifts: { [key: string]: Shift[] } = {};
      response.data.allShifts.map((day: any) => {
        shifts[day.date] = day.shifts;
      });

      return shifts;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Delete Shift
export const deleteShift = createAsyncThunk(
  "shift/deleteShift",
  async (shiftId: number, thunkAPI) => {
    try {
      const response: any = await client.mutate({
        mutation: DELETE_SHIFT,
        variables: {
          shiftDeleteId: shiftId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.shiftDelete.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Email Options
export const getEmailOptions = createAsyncThunk<any, any, AsyncThunkConfig>(
  "shift/getEmailOptions",
  async (_, thunkAPI) => {
    // Now handled on backend
    // const id = thunkAPI.getState().auth.user?.regionId;
    let url = `/email-options`;

    try {
      // const response = await authFetch.get(url);
      const response = await axios.get(url);
      const emailOptions = response.data;

      return await emailOptions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Create notes
export const createShiftNotes = createAsyncThunk<any, any, AsyncThunkConfig>(
  "shift/createShiftNotes",
  async (notesArgs: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_SHIFT_NOTE,
        variables: { shiftNoteArgs: notesArgs },
      });
      return response.data.shiftNoteCreate.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Shifts
export const getShiftNotes = createAsyncThunk(
  "shift/getShiftNotes",
  async (shiftId: string, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_SHIFT_NOTES_BY_SHIFTID,
        variables: {
          shiftId: shiftId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.shiftNotesById;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Open Shifts
export const getOpenShifts = createAsyncThunk<any, any, AsyncThunkConfig>(
  "shift/getOpenShifts",
  async (filterArgs: any, thunkAPI) => {
    const { userId, toDate, fromDate, staffType } = filterArgs;

    // openShiftFilters location fetched from thunk State
    const location = thunkAPI.getState().shift.openShiftFilters?.location;

    try {
      const state: any = thunkAPI.getState();
      const regionsId = state.venueMaster.selectedRegions.map(
        (region: any) => +region.id
      );
      const response: any = await client.query({
        query: GET_OPEN_SHIFTS,
        variables: {
          openShiftsFilter: {
            location: location,
            fromDate: fromDate,
            toDate: toDate,
            regions: regionsId,
            staffType: staffType,
            userId: userId,
          },
        },
        fetchPolicy: "network-only",
      });
      return response.data.openShifts;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get My Shifts
export const getMyShifts = createAsyncThunk(
  "shift/getMyShifts",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: MY_SHIFT,
        fetchPolicy: "network-only",
      });

      return await response.data.myShifts;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Create requested Shifts
export const createRequestedShifts = createAsyncThunk(
  "shift/createRequestedShifts",
  async (requestedShiftArgs: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_REQUESTED_SHIFT,
        variables: requestedShiftArgs,
      });
      return response.data.requestedShiftCreate.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get All requested Shifts
export const getAllRequestedShifts = createAsyncThunk<
  any,
  any,
  AsyncThunkConfig
>("shift/getAllRequestedShifts", async (filterArgs: any, thunkAPI) => {
  const { toDate, fromDate } = filterArgs;

  const venueId = thunkAPI.getState().shift.requestedShiftFilters?.venueId;

  try {
    const state: any = thunkAPI.getState();
    const regionsId = state.venueMaster.selectedRegions.map(
      (region: any) => +region.id
    );
    const response: any = await client.query({
      query: GET_REQUESTED_SHIFTS,
      variables: {
        requestedShiftArgs: {
          venueId: venueId === 0 ? null : venueId,
          fromDate: fromDate,
          toDate: toDate,
          regions: regionsId,
        },
      },
      fetchPolicy: "network-only",
    });
    return response.data.requestedShifts;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response.data.msg);
  }
});

// Assign a Requested Shift to a LA
export const updateRequestedShift = createAsyncThunk(
  "shift/updateRequestedShift",
  async (shiftArgs: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: ASSIGN_SHIFT,
        variables: {
          assignShiftInput: {
            requestedShift: {
              startLocal: shiftArgs.shift.startLocal,
              endLocal: shiftArgs.shift.endLocal,
              venueId: shiftArgs.shift.venueId,
              regionId: shiftArgs.shift.regionId,
            },
            shiftId: shiftArgs.shiftId,
            userId: shiftArgs.userId,
          },
        },
      });
      const assignedShift = response.data.shiftAssign.message;
      return await assignedShift;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get My Applied Shifts
export const getMyAppliedShifts = createAsyncThunk(
  "shift/getMyAppliedShifts",
  async (_, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const regionsId = state.venueMaster.selectedRegions.map(
        (region: any) => +region.id
      );
      const response: any = await client.query({
        query: GET_APPLIED_SHIFTS,
        variables: {
          regions: regionsId,
        },
        fetchPolicy: "network-only",
      });
      return response.data.appliedShifts;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Cancel Applied Shift
export const deleteAppliedShift = createAsyncThunk(
  "shift/deleteAppliedShift",
  async (appliedShiftId: string, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: DELETE_REQUESTED_SHIFT,
        variables: {
          shiftId: +appliedShiftId,
        },
      });
      return response.data.requestedShiftDelete.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Create Shift Report
export const createShiftReport = createAsyncThunk<any, any, AsyncThunkConfig>(
  "shift/createShiftReport",
  async (shiftReportArgs: any, thunkAPI) => {
    // Moved to component
    // const userId = thunkAPI.getState().auth.user?.id;

    try {
      const response = await client.mutate({
        mutation: CREATE_SHIFT_REPORT,
        variables: {
          shiftReportArgs: shiftReportArgs,
        },
      });
      return response.data.shiftReportCreate.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get Shift Report Questions
export const getShiftReportQuestions = createAsyncThunk(
  "shift/getShiftReportQuestions",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_SHIFT_REPORT_QUESTIONS,
        fetchPolicy: "network-only",
      });
      return response.data.shiftReportQuestions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Get shifts that are not paid
export const getShiftsNotPaid = createAsyncThunk(
  "shift/getShiftsNotPaid",
  async (_, thunkAPI) => {
    try {
      const response: any = await client.query({
        query: GET_SHIFTS_NOT_PAID,
        fetchPolicy: "network-only",
      });
      return response.data.shiftsNotPaid;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Update shifts as Paid
export const updateShiftsPaid = createAsyncThunk(
  "shift/updateShiftsPaid",
  async (shiftsArr: any, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: UPDATE_SHIFTS_PAID,
        variables: {
          shiftsId: shiftsArr,
        },
      });
      return response.data.shiftsPaidUpdate.message;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// Third Party Contractor Management: Get Staff Type
export const getThirdPartyContractors = createAsyncThunk(
  "shift/getThirdPartyContractors",
  async (_, thunkAPI) => {
    try {
      const state: any = thunkAPI.getState();
      const regionsId = state.venueMaster.selectedRegions.map(
        (region: any) => +region.id
      );
      const response = await client.query({
        query: GET_THIRD_PARTY_CONTRACTORS,
        variables: {
          regions: regionsId,
        },
      });
      return response.data.thirdPartyContractors;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const shiftSlice = createSlice({
  name: "shift",
  initialState,
  reducers: {
    message: (state, action) => {
      state.message = action.payload;
    },
    logoutUser: (state, action) => {
      state.isError = false;
      state.isSuccess = false;
      state.isLoading = false;
      state.message = "";
      // state.user = '';
      // state.jwtToken = '';
      // state.userLocation = '';
      // state.siteAdministrator = '';
      // state.eventCoordinator = '';
      state.locationsOptions = [];
      state.staffTypeOptions = [];
      state.staffMembersOptions = [];
      state.shiftStatusOptions = [];
      state.emailOptions = [];
      state.shiftReportStatusOptions = [];
      state.shifts = [];
      state.openShifts = [];
      state.myShiftsHistory = [];
      state.myShiftsUpcoming = [];
      state.requestedShifts = [];
      state.appliedShifts = [];
      state.shiftFilters = {} as ShiftFilter;
      state.openShiftFilters = {} as OpenShiftFilter;
      state.requestedShiftFilters = {} as RequestedShiftFilter;
      state.shiftNoteId = 0;
      state.notes = [];
      state.shiftReportQuestions = [];
      state.shiftsFinance = [];
      state.thirdPartyContractorFilters = {} as ThirdPatyContractorFilter;
    },
    handleShiftFilters: (state, action) => {
      state.shiftFilters.toDate = action.payload.toDate;
      state.shiftFilters.fromDate = action.payload.fromDate;
      state.shiftFilters.assignedTo = action.payload.assignedTo;
      state.shiftFilters.weekday = action.payload.weekday;
      state.shiftFilters.isReported = action.payload.isReported;
      state.shiftFilters.shiftStatus = action.payload.shiftStatus;
    },
    handleOpenShiftFilters: (state, action) => {
      state.openShiftFilters.toDate = action.payload.toDate;
      state.openShiftFilters.fromDate = action.payload.fromDate;
      state.openShiftFilters.location = action.payload.location;
      state.openShiftFilters.staffType = action.payload.staffType;
    },
    handleRequestedShiftFilters: (state, action) => {
      state.requestedShiftFilters.toDate = action.payload.toDate;
      state.requestedShiftFilters.fromDate = action.payload.fromDate;
      state.requestedShiftFilters.venueId = action.payload.venueId;
    },
    handleThirdPartyContractorFilters: (state, action) => {
      state.thirdPartyContractorFilters.toDate = action.payload.toDate;
      state.thirdPartyContractorFilters.fromDate = action.payload.fromDate;
      state.thirdPartyContractorFilters.contractorId =
        action.payload.contractorId;
      state.thirdPartyContractorFilters.weekday = action.payload.weekday;
    },
    addShiftNoteId: (state, action) => {
      state.shiftNoteId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // .addCase(authenticateToken.pending, (state, action) => {
      //   state.isLoading = true;
      // })
      // .addCase(authenticateToken.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.user = action.payload.user;
      //   state.jwtToken = action.payload.jwtToken;
      //   state.userLocation = action.payload.userLocation;
      //   state.siteAdministrator = action.payload.user?.siteAdministrator;
      //   state.eventCoordinator = action.payload.user?.eventCoordinator;
      // })
      // .addCase(authenticateToken.rejected, (state, action) => {
      //   state.isLoading = false;
      // })
      .addCase(getAllShifts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.shifts = action.payload;
      })
      .addCase(getAllShifts.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload as string;
      })
      .addCase(getAllLocations.fulfilled, (state, action) => {
        state.locationsOptions = action.payload;
      })
      .addCase(getStaffType.fulfilled, (state, action) => {
        state.staffTypeOptions = action.payload;
      })
      .addCase(getThirdPartyContractors.fulfilled, (state, action) => {
        state.thirdPartyContractorOptions = action.payload;
      })
      .addCase(getShiftStatus.fulfilled, (state, action) => {
        state.shiftStatusOptions = action.payload;
      })
      .addCase(updateShiftStatus.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(updateShiftStatus.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error updating shift status"
        );
      })
      .addCase(getAllStaffMembers.fulfilled, (state, action) => {
        state.staffMembersOptions = action.payload;
      })
      .addCase(deleteShift.pending, (state, action) => {
        state.isLoading = true;
        // state.message = action.payload.msg;
      })
      .addCase(deleteShift.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(deleteShift.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Error deleting shift");
      })
      .addCase(getEmailOptions.pending, (state, action) => {
        state.isLoading = true;
        // state.message = action.payload.msg;
      })
      .addCase(getEmailOptions.fulfilled, (state, action) => {
        state.emailOptions = action.payload.data.emailOptions;
        state.isLoading = false;
      })
      .addCase(getEmailOptions.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting email options"
        );
      })
      .addCase(getShiftNotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getShiftNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        state.notes = action.payload;
      })
      .addCase(getShiftNotes.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting shift notes"
        );
      })
      .addCase(createShiftNotes.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createShiftNotes.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(createShiftNotes.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error creating shift notes"
        );
      })
      .addCase(getOpenShifts.pending, (state, action) => {
        state.isLoading = true;
        // state.message = action.payload.msg;
      })
      .addCase(getOpenShifts.fulfilled, (state, action) => {
        state.openShifts = action.payload;
        state.isLoading = false;
      })
      .addCase(getOpenShifts.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting open shifts"
        );
      })
      .addCase(getMyShifts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMyShifts.fulfilled, (state, action) => {
        state.myShiftsHistory = action.payload.updatedHistoryData;
        state.myShiftsUpcoming = action.payload.updatedUpcomingData;
        state.isLoading = false;
      })
      .addCase(getMyShifts.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Error getting my shifts");
      })
      .addCase(createRequestedShifts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createRequestedShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(createRequestedShifts.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error creating requested shift"
        );
        // state.myShifts = action.payload;
        // state.message = action.payload.msg;
      })
      .addCase(getAllRequestedShifts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllRequestedShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.requestedShifts = action.payload;
      })
      .addCase(getAllRequestedShifts.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting requested shifts"
        );
      })
      .addCase(updateRequestedShift.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateRequestedShift.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(updateRequestedShift.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error updating requested shift"
        );
      })
      .addCase(getMyAppliedShifts.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getMyAppliedShifts.fulfilled, (state, action) => {
        state.isLoading = false;
        state.appliedShifts = action.payload;
      })
      .addCase(getMyAppliedShifts.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting my applied shifts"
        );
      })
      .addCase(deleteAppliedShift.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(deleteAppliedShift.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(deleteAppliedShift.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error deleting applied shift"
        );
      })
      .addCase(createShiftReport.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createShiftReport.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(createShiftReport.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error creating shift report"
        );
      })
      .addCase(getShiftReportQuestions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getShiftReportQuestions.fulfilled, (state, action) => {
        state.shiftReportQuestions = action.payload;
        state.isLoading = false;
      })
      .addCase(getShiftReportQuestions.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting shift report questions"
        );
      })
      .addCase(getShiftsNotPaid.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getShiftsNotPaid.fulfilled, (state, action) => {
        state.shiftsFinance = action.payload.shifts;
        state.isLoading = false;
      })
      .addCase(getShiftsNotPaid.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting shifts not paid"
        );
      })
      .addCase(updateShiftsPaid.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(updateShiftsPaid.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload);
      })
      .addCase(updateShiftsPaid.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error updating shifts paid"
        );
      })
      .addCase(getVenuesTree.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVenuesTree.fulfilled, (state, action) => {
        state.isLoading = false;
        state.venues = action.payload;
      })
      .addCase(getVenuesTree.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting venues tree"
        );
      })
      .addCase(getShiftPaymentStatuses.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getShiftPaymentStatuses.fulfilled, (state, action) => {
        console.log("🚀🚀🚀🚀 ~ .addCase ~ action:", action);
        state.isLoading = false;
        state.shiftPaymentStatuses = action.payload;
      })
      .addCase(getShiftPaymentStatuses.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(
          action.error.message ?? "Error getting shift payment statuses"
        );
      })
      .addCase(getStaffTypeByRegion.fulfilled, (state, action) => {
        state.staffTypeOptions = action.payload;
      });
  },
});

export const shiftSelector = (state: RootState) => state.shift;

export const {
  message,
  handleShiftFilters,
  handleOpenShiftFilters,
  handleRequestedShiftFilters,
  handleThirdPartyContractorFilters,
  addShiftNoteId,
  logoutUser,
} = shiftSlice.actions;
export default shiftSlice.reducer;
