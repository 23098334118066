import { createContext, useContext, useEffect, useState } from "react";
import {
  DivisionByIdQuery,
  GameslotRelationalQuery,
  VenueOverviewDayQuery,
} from "../generated/graphql";
import { Pagination } from "../types/types";
import { z } from "zod";
import dayjs, { Dayjs } from "dayjs";
import { dateWithoutTimezone } from "../utils/timeFunctions";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);
export const blankVenueOverviewCreateGameslotForm = {
  venue: {
    id: 0,
    name: "",
  },
  startDateTimeLocal: dateWithoutTimezone(new Date()),
  duration: 0,
  numberOfGameslots: 0,
  weeks: 0,
};
export const initialVenueOverviewCreateGameslotForm = {
  venue: {
    id: 0,
    name: "",
  },
  startDateTimeLocal: dateWithoutTimezone(new Date()),
  duration: 60,
  numberOfGameslots: 1,
  weeks: 1,
};

export type VenueOverviewValidDay = {
  startDate: Dayjs;
  endDate: Dayjs;
};

export type VenueOverviewTable = {
  allDaysInRange: Map<string, VenueOverviewValidDay>;
  venueRows: VenueOverviewRow[];
  currentGameslots: GameslotRelationalQuery["gameslotRelational"];
};

export type VenueOverviewRow = {
  venue: VenueOverviewDayQuery["venueOverviewDay"]["contractDetails"][0]["venue"];
  contract: VenueOverviewDayQuery["venueOverviewDay"]["contractDetails"][0]["contract"];
  contractItems: VenueOverviewDayQuery["venueOverviewDay"]["contractDetails"][0]["contractItems"];
  hoursOfOperation: Set<number>;
};

export const VenueOverviewFilterSchema = z.object({
  regionId: z.number(),
  venueTypeId: z.number(),
  startDate: z.date(),
  endDate: z.date(),
  venueId: z.number(),
});

export type VenueOverviewFilterSchemaType = z.infer<
  typeof VenueOverviewFilterSchema
>;

export const VenueOverviewGameslotFormSchema = z.object({
  venue: z.object({
    id: z.number(),
    name: z.string(),
  }),
  startDateTimeLocal: z.string(),
  duration: z.number(),
  numberOfGameslots: z.number(),
  weeks: z.number(),
});

export type VenueOverviewGameslotFormSchemaType = z.infer<
  typeof VenueOverviewGameslotFormSchema
>;

export const VenueOverviewCreateGameslotFormPreviewSchema = z.object({
  venueId: z.number(),
  startDateTimeLocal: z.string(),
  duration: z.number(),
});

export type VenueOverviewCreateGameslotFormPreviewType = z.infer<
  typeof VenueOverviewCreateGameslotFormPreviewSchema
>;

export const VenueOverviewUpdateGameslotDataSchema = z.object({
  id: z.number(),
  venueId: z.number(),
  startDateTimeLocal: z.string(),
  duration: z.number(),
});

export type VenueOverviewUpdateGameslotDataType = z.infer<
  typeof VenueOverviewUpdateGameslotDataSchema
>;

export type VenueOverviewContextType = {
  filter: VenueOverviewFilterSchemaType;
  setFilter: React.Dispatch<
    React.SetStateAction<VenueOverviewFilterSchemaType>
  >;
  venueOverviewData: VenueOverviewTable;
  setVenueOverviewData: React.Dispatch<
    React.SetStateAction<VenueOverviewTable>
  >;
  showCreateGameslotModal: boolean;
  setShowCreateGameslotModal: React.Dispatch<React.SetStateAction<boolean>>;
  showUpdateGameslotModal: boolean;
  setShowUpdateGameslotModal: React.Dispatch<React.SetStateAction<boolean>>;
  venueOverviewCreateGameslotForm: VenueOverviewGameslotFormSchemaType;
  setVenueOverviewCreateGameslotForm: React.Dispatch<
    React.SetStateAction<VenueOverviewGameslotFormSchemaType>
  >;
  venueOverviewCreateGameslotFormPreview: VenueOverviewCreateGameslotFormPreviewType[];
  setVenueOverviewCreateGameslotFormPreview: React.Dispatch<
    React.SetStateAction<VenueOverviewCreateGameslotFormPreviewType[]>
  >;
  venueOverviewUpdateGameslotData:
    | VenueOverviewUpdateGameslotDataType
    | undefined;
  setVenueOverviewUpdateGameslotData: React.Dispatch<
    React.SetStateAction<VenueOverviewUpdateGameslotDataType | undefined>
  >;
  activeVenueOverviewRow: VenueOverviewRow | undefined;
  setActiveVenueOverviewRow: React.Dispatch<
    React.SetStateAction<VenueOverviewRow | undefined>
  >;
  venueOverviewCreateGameslotFormErrors: string[];
  setVenueOverviewCreateGameslotFormErrors: React.Dispatch<
    React.SetStateAction<string[]>
  >;
};

const VenueOverviewContextDefault: VenueOverviewContextType = {
  filter: {
    regionId: 0,
    venueTypeId: 0,
    startDate: dayjs(new Date())
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    endDate: dayjs(new Date())
      .add(4, "week")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    venueId: 0,
  },
  setFilter: () => {},
  venueOverviewData: {
    allDaysInRange: new Map([]),
    venueRows: [],
    currentGameslots: [],
  },
  setVenueOverviewData: () => {},
  showCreateGameslotModal: false,
  setShowCreateGameslotModal: () => {},
  showUpdateGameslotModal: false,
  setShowUpdateGameslotModal: () => {},
  venueOverviewCreateGameslotForm: initialVenueOverviewCreateGameslotForm,
  setVenueOverviewCreateGameslotForm: () => {},
  venueOverviewCreateGameslotFormPreview: [],
  setVenueOverviewCreateGameslotFormPreview: () => {},
  venueOverviewUpdateGameslotData: undefined,
  setVenueOverviewUpdateGameslotData: () => {},
  activeVenueOverviewRow: undefined,
  setActiveVenueOverviewRow: () => {},
  venueOverviewCreateGameslotFormErrors: [],
  setVenueOverviewCreateGameslotFormErrors: () => {},
};

const VenueOverviewContext = createContext<VenueOverviewContextType>(
  VenueOverviewContextDefault
);

export const VenueOverviewContextProvider = ({ children }: any) => {
  // Basic filter add new id types here for more filtering
  const [filter, setFilter] = useState<{
    regionId: number;
    venueTypeId: number;
    startDate: Date;
    endDate: Date;
    venueId: number;
  }>({
    regionId: 0,
    venueTypeId: 0,
    startDate: dayjs(new Date())
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    endDate: dayjs(new Date())
      .add(4, "week")
      .set("hour", 0)
      .set("minute", 0)
      .set("second", 0)
      .toDate(),
    venueId: 0,
  });
  const [venueOverviewData, setVenueOverviewData] =
    useState<VenueOverviewTable>({
      allDaysInRange: new Map([]),
      venueRows: [],
      currentGameslots: [],
    });
  const [showCreateGameslotModal, setShowCreateGameslotModal] =
    useState<boolean>(false);
  const [showUpdateGameslotModal, setShowUpdateGameslotModal] =
    useState<boolean>(false);
  const [venueOverviewCreateGameslotForm, setVenueOverviewCreateGameslotForm] =
    useState<VenueOverviewGameslotFormSchemaType>(
      initialVenueOverviewCreateGameslotForm
    );
  const [
    venueOverviewCreateGameslotFormPreview,
    setVenueOverviewCreateGameslotFormPreview,
  ] = useState<VenueOverviewCreateGameslotFormPreviewType[]>([]);
  const [venueOverviewUpdateGameslotData, setVenueOverviewUpdateGameslotData] =
    useState<VenueOverviewUpdateGameslotDataType | undefined>(undefined);
  const [activeVenueOverviewRow, setActiveVenueOverviewRow] = useState<
    VenueOverviewRow | undefined
  >();
  const [
    venueOverviewCreateGameslotFormErrors,
    setVenueOverviewCreateGameslotFormErrors,
  ] = useState<string[]>([]);

  const value = {
    filter,
    setFilter,
    venueOverviewData,
    setVenueOverviewData,
    showCreateGameslotModal,
    setShowCreateGameslotModal,
    venueOverviewCreateGameslotForm,
    setVenueOverviewCreateGameslotForm,
    activeVenueOverviewRow,
    setActiveVenueOverviewRow,
    venueOverviewCreateGameslotFormPreview,
    setVenueOverviewCreateGameslotFormPreview,
    venueOverviewUpdateGameslotData,
    setVenueOverviewUpdateGameslotData,
    venueOverviewCreateGameslotFormErrors,
    setVenueOverviewCreateGameslotFormErrors,
    showUpdateGameslotModal,
    setShowUpdateGameslotModal,
  };

  useEffect(() => {
    // Create game slots based on the form input
    const { startDateTimeLocal, venue, numberOfGameslots, duration, weeks } =
      venueOverviewCreateGameslotForm;

    // If there's no activeVenueOverviewRow, we cannot proceed
    if (!activeVenueOverviewRow) {
      setVenueOverviewCreateGameslotFormPreview([]);
      return;
    }

    let gameslots: VenueOverviewCreateGameslotFormPreviewType[] = [];
    let validationErrors: string[] = [];

    for (let i = 0; i < weeks; i++) {
      for (let j = 0; j < numberOfGameslots; j++) {
        const gameslotStart = dayjs(startDateTimeLocal)
          .add(i, "weeks")
          .add(j * duration, "minutes");
        const gameslotEnd = gameslotStart.add(duration, "minutes");

        // Check if the game slot fits within any contract item
        const fitsWithinContractItem =
          activeVenueOverviewRow.contractItems.some((contractItem) => {
            const contractStart = dayjs(contractItem.startDateTimeLocal);
            const contractEnd = dayjs(contractItem.endDateTimeLocal);

            return (
              gameslotStart.isSameOrAfter(contractStart) &&
              gameslotEnd.isSameOrBefore(contractEnd) &&
              !contractItem.isExcluded
            );
          });

        if (fitsWithinContractItem) {
          gameslots.push({
            startDateTimeLocal: gameslotStart.format("YYYY-MM-DD HH:mm:ss"),
            duration: duration,
            venueId: venue.id,
          });
        } else {
          // Record the validation error
          validationErrors.push(
            `${gameslotStart.format("YYYY-MM-DD HH:mm:ss")}`
          );
        }
      }
    }

    // Set validation errors if any
    setVenueOverviewCreateGameslotFormErrors(validationErrors);
    // Update the game slot preview
    setVenueOverviewCreateGameslotFormPreview(gameslots);
  }, [
    venueOverviewCreateGameslotForm,
    activeVenueOverviewRow,
    setVenueOverviewCreateGameslotFormErrors,
    setVenueOverviewCreateGameslotFormPreview,
  ]);

  return (
    <VenueOverviewContext.Provider value={value}>
      {children}
    </VenueOverviewContext.Provider>
  );
};

export const useVenueOverviewContext = () => useContext(VenueOverviewContext);
