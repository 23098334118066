import React from "react";
import Headline1Variable from "../../UI/Text/Headline/Headline1Variable";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "../../UI/shadcn/popover";
import { Button } from "../../UI/shadcn/button";
import { useNavigate } from "react-router-dom";

const WeatherNavBar = () => {
  const navigate = useNavigate();

  return <Button onClick={() => navigate("/weather")}>Weather</Button>;
};

export default WeatherNavBar;
