"use client";

import * as React from "react";
import { format } from "date-fns";
import { Calendar as CalendarIcon } from "lucide-react";

import { cn } from "../../../../lib/utils";
import { Button } from "../button";
import { Calendar } from "../calendar";
import { Popover, PopoverContent, PopoverTrigger } from "../popover";
import { TimePickerDemo } from "./time-picker-demo";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

export function DatePicker({
  date,
  setDate,
  label,
  className,
  disabled = false,
}: {
  date: Date | undefined;
  setDate: (date: Date | undefined) => void;
  label?: string;
  className?: string;
  disabled?: boolean;
}) {
  return (
    <div className={`flex flex-col text-black`}>
      {label && <div className={`px-3 text-xs font-medium`}>{label}</div>}
      <Popover>
        <PopoverTrigger
          asChild
          disabled={disabled}
        >
          <Button
            disabled={disabled}
            variant={"outline"}
            className={cn(
              "w-[280px] justify-start text-left font-normal rounded-xl border border-secondary-80 hover:border-secondary-50 focus-visible:ring-2 focus-visible:ring-offset-0 focus-visible:ring-primary-80 focus-visible:border-primary-80",
              !date && "text-muted-foreground",
              className
            )}
          >
            <CalendarIcon className="w-4 h-4 mr-2" />
            {date ? format(date, "PPP") : <span>Pick a date</span>}
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-auto p-0">
          <Calendar
            mode="single"
            selected={date}
            onSelect={setDate}
            initialFocus
            defaultMonth={date}
          />
        </PopoverContent>
      </Popover>
    </div>
  );
}
