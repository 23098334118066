import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "@/src/app/store";
import LoadingDialog from "../../UI/Dialog/LoadingDialog";
import ReactQuill from "react-quill";
import Button from "../../UI/Button/Button";
import Body1 from "../../UI/Text/Body/Body1";
import Headline2Variable from "../../UI/Text/Headline/Headline2Variable";
import { IconButton } from "@mui/material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import { header } from "./Headers";
import { footer } from "./Footers";
import { emailButton } from "./EmailButton";
import { FormFieldSelect } from "../../UI/FormField/FormFieldDropdown/FormFieldSelectV2";
import Caption1 from "../../UI/Text/Caption/Caption1";
import FormFieldControlled from "../../UI/FormField/FormFieldControlled";
import FormFieldViewOnly from "../../UI/FormField/FormFieldViewOnly/FormFieldViewOnly";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "../../UI/shadcn/dialog";
import {
  displayAlertError,
  displayAlertSuccess,
} from "../../../app/globalSlice";
import {
  GetEmailTemplatesQuery,
  ListAllOperations,
  useCreateEmailTemplateMutation,
  useGetEmailTemplatesQuery,
  useGetEmailTemplateTypesQuery,
  useUpdateEmailTemplateMutation,
} from "../../../generated/graphql";
import { z } from "zod";
import { emailHyperLink } from "./EmailHyperLink";

const EmailSchema = z.object({
  id: z.number().optional(),
  subject: z.string(),
  body: z.string(),
  name: z.string(),
  typeId: z.number(),
});

type EmailSchemaType = z.infer<typeof EmailSchema>;

type EmailTemplateVariable = {
  id: number;
  name: string;
  regex: RegExp;
  validTemplateTypes: number[];
};

const initialEmailState = {
  id: undefined,
  subject: "",
  body: "<p></br><p>",
  name: "",
  typeId: 0,
};

const EmailTemplateManager = () => {
  const { isLoading }: any = useSelector(
    (state: RootState) => state.venueMaster
  );
  const dispatch = useDispatch<AppDispatch>();

  const [email, setEmail] = useState<EmailSchemaType>(initialEmailState);
  const [allEmails, setAllEmails] = useState<
    GetEmailTemplatesQuery["getEmailTemplates"]
  >([]);
  const [invalidEmailVars, setInvalidEmailVars] = useState<string[]>([]);
  const [leagueRuleOption, setLeagueRuleOption] = useState<number>(0);
  const [validVariables, setValidVariables] = useState<EmailTemplateVariable[]>(
    [
      {
        id: 1,
        name: "sportRegex",
        regex: /%%sport%%/g,
        validTemplateTypes: [1],
      },
      {
        id: 2,
        name: "dayOfWeekRegex",
        validTemplateTypes: [1],
        regex: /%%day_of_week%%/g,
      },
      {
        id: 3,
        name: "teamNameRegex",
        validTemplateTypes: [1],
        regex: /%%team_name%%/g,
      },
      {
        id: 4,
        name: "firstNameRegex",
        validTemplateTypes: [1, 2],
        regex: /%%first_name%%/g,
      },
      {
        id: 5,
        name: "buttonRegex",
        regex:
          /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
        validTemplateTypes: [1, 2],
      },
      {
        id: 6,
        name: "ruleLinkRegex",
        regex: /%%ruleLink%%/g,
        validTemplateTypes: [1],
      },
      {
        id: 7,
        name: "ruleTextRegex",
        regex: /%%ruleText_([a-zA-Z0-9\s._%+-]+)%%/g,
        validTemplateTypes: [1],
      },
      {
        id: 8,
        name: "ruleButtonRegex",
        regex: /%%ruleButton_([a-zA-Z0-9\s._%+-]+)%%/g,
        validTemplateTypes: [1],
      },
      {
        id: 9,
        name: "divisionNameRegex",
        regex: /%%division_name%%/g,
        validTemplateTypes: [1],
      },
    ]
  );

  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  function validateBody() {
    // Find all instances of text that don't match any of the regex patterns
    const regex = /%%[^%]+%%/g;
    const matchesBody = email.body.match(regex);
    const matchesSubject = email.subject.match(regex);
    let invalidMatches: string[] = [];
    if (matchesBody) {
      invalidMatches = matchesBody.filter((match) => {
        return !validVariables.some((validReg) => {
          return (
            match.match(validReg.regex) &&
            validReg.validTemplateTypes.includes(email.typeId)
          );
        });
      });
    }
    if (matchesSubject) {
      invalidMatches.push(
        ...matchesSubject.filter((match: string) => {
          return !validVariables.some(
            (validReg) =>
              validReg.validTemplateTypes.includes(email.typeId) &&
              match.match(validReg.regex)
          );
        })
      );
    }
    // If there are any invalid matches return without sending
    if (invalidMatches.length > 0) {
      setInvalidEmailVars(invalidMatches);
      return false;
    }
    return true;
  }

  const {
    data: emailTemplateData,
    loading: loadingEmailTemplate,
    refetch: refetchEmailTemplates,
  } = useGetEmailTemplatesQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setAllEmails(data.getEmailTemplates);
    },
  });

  const { data: templateTypeData, loading: loadingTemplateType } =
    useGetEmailTemplateTypesQuery({});

  const [createEmailTemplate, { loading: loadingCreate }] =
    useCreateEmailTemplateMutation({
      variables: {
        emailTemplateInput: {
          name: email?.name,
          subject: email?.subject,
          body: email?.body,
          typeId: +email?.typeId,
        },
      },
      refetchQueries: [ListAllOperations.Query.GetEmailTemplates],
      onCompleted: (data) => {
        if (data.createEmailTemplate.emailTemplate) {
          setEmail(data.createEmailTemplate.emailTemplate);
        }
        if (data.createEmailTemplate.message.success) {
          dispatch(
            displayAlertSuccess(data.createEmailTemplate.message.message)
          );
        } else {
          dispatch(displayAlertError(data.createEmailTemplate.message.message));
        }
      },
      onError: (err) => {
        dispatch(displayAlertError("Something went wrong."));
      },
    });

  const [updateEmailTemplate, { loading: loadingUpdate }] =
    useUpdateEmailTemplateMutation({
      variables: {
        emailTemplateInput: {
          id: email?.id,
          name: email?.name,
          subject: email?.subject,
          body: email?.body,
          typeId: +email?.typeId,
        },
      },
      refetchQueries: [ListAllOperations.Query.GetEmailTemplates],
      onCompleted: (data) => {
        if (data.updateEmailTemplate.emailTemplate) {
          setEmail(data.updateEmailTemplate.emailTemplate);
        }
        if (data.updateEmailTemplate.message.success) {
          dispatch(
            displayAlertSuccess(data.updateEmailTemplate.message.message)
          );
        } else {
          dispatch(displayAlertError(data.updateEmailTemplate.message.message));
        }
      },
      onError: (err) => {
        dispatch(displayAlertError("Something went wrong."));
      },
    });

  useEffect(() => {
    refetchEmailTemplates();
  }, [email.typeId]);

  useEffect(() => {
    if (iframeRef.current && email !== undefined) {
      const formattedBody = `${header}${email.body
        .replace(
          /%%button_(https:\/\/[a-zA-Z0-9._%+-\/]+)_([a-zA-Z0-9\s._%+-]+)%%/g,
          (_, link, text) => {
            return emailButton(link, text);
          }
        )
        .replace(/%%ruleButton_([a-zA-Z0-9\s._%+-]+)%%/g, (_, text) => {
          return emailButton("https://jamsports.com/", text);
        })
        .replace(/%%ruleText_([a-zA-Z0-9\s._%+-]+)%%/g, (_, text) => {
          return emailHyperLink("https://jamsports.com/", text);
        })}${footer()}`;
      const blob = new Blob([formattedBody], { type: "text/html" });
      iframeRef.current.src = URL.createObjectURL(blob);
    }
  }, [email]);

  if (
    isLoading ||
    loadingEmailTemplate ||
    loadingCreate ||
    loadingUpdate ||
    loadingTemplateType
  ) {
    return <LoadingDialog open={true} />;
  }

  return (
    <main className="flex flex-col gap-4">
      <h2>Email Template Manager</h2>
      <div className="flex flex-col">
        <div className="flex flex-row items-end justify-start gap-4">
          <div className="w-80 max-w-80">
            <FormFieldSelect
              name="templateSelect"
              value={email?.id?.toString() ?? "0"}
              inputChange={(value) => {
                const newEmail = allEmails.find(
                  (template) => template.id === +value
                );
                if (newEmail) {
                  setEmail(newEmail);
                }
              }}
              label="Template"
              placeholder="Select Email Temaplte"
            >
              {[
                { id: 0, name: "Select a Template" },
                ...(emailTemplateData !== undefined
                  ? emailTemplateData.getEmailTemplates.map((template) => {
                      return { id: template.id, name: template.name };
                    })
                  : []),
              ]}
            </FormFieldSelect>
          </div>
          <div className="whitespace-nowrap">
            <Button
              variant="primary"
              onClick={() => {
                setEmail({
                  name: "",
                  subject: "",
                  body: "<p>New Template</p>",
                  typeId: 1,
                });
              }}
            >
              New Template
            </Button>
          </div>
        </div>
      </div>

      {email !== undefined && email !== initialEmailState && (
        <div className="flex flex-row justify-start w-full gap-4">
          <div className="flex flex-col w-1/2 gap-4">
            <div className="flex flex-col gap-2">
              {email.id ? (
                <FormFieldViewOnly
                  label="Type"
                  text={
                    (templateTypeData !== undefined
                      ? templateTypeData.getEmailTemplateTypes.find(
                          (type) => type.id === email.typeId
                        )?.name
                      : null) ?? "Unknown"
                  }
                />
              ) : (
                <FormFieldSelect
                  name="templateSelect"
                  value={email?.typeId?.toString() ?? "0"}
                  inputChange={(value) => {
                    setEmail((prev) => ({
                      ...prev,
                      typeId: +value,
                    }));
                  }}
                  label="Type"
                  placeholder="Select Template Type"
                >
                  {[
                    { id: 0, name: "Select Template Type" },
                    ...(templateTypeData !== undefined
                      ? templateTypeData.getEmailTemplateTypes
                      : []),
                  ]}
                </FormFieldSelect>
              )}
              <FormFieldControlled
                onChange={(value) => {
                  setEmail((prev) => ({
                    ...prev,
                    name: value.target.value,
                  }));
                }}
                value={email.name}
                label="Name"
              />
              <FormFieldControlled
                onChange={(value) => {
                  setEmail((prev) => ({
                    ...prev,
                    subject: value.target.value,
                  }));
                }}
                value={email.subject}
                label="Subject"
              />
            </div>

            <div className="mb-[46px]">
              <div className="flex flex-col">
                <Caption1 className="px-3">Body</Caption1>
                <ReactQuill
                  theme="snow"
                  value={email.body}
                  onChange={(text: string) =>
                    setEmail((prev) => ({
                      ...prev,
                      body: text,
                    }))
                  }
                  style={{ height: "400px", width: "100%" }}
                />
              </div>
            </div>
            <div>
              <Button
                variant="primary"
                onClick={() => {
                  if (validateBody()) {
                    email.id === undefined
                      ? createEmailTemplate()
                      : updateEmailTemplate();
                  }
                }}
              >
                {email.id === undefined ? "Create" : "Update"}
              </Button>
              <Button
                variant="primary"
                onClick={() => {
                  validateBody();
                }}
              >
                Test
              </Button>
            </div>
            <Dialog
              open={invalidEmailVars.length > 0}
              onOpenChange={(value) => {
                if (!value) setInvalidEmailVars([]);
              }}
            >
              <DialogContent className="max-w-96">
                <DialogHeader>
                  <DialogTitle>Email Variable Validation</DialogTitle>
                </DialogHeader>
                <DialogDescription>
                  <div className="flex flex-col gap-4">
                    <Body1>
                      This email template contains the following invalid
                      variables
                    </Body1>
                    {invalidEmailVars.map((invalidVars) => {
                      return <Body1>{invalidVars}</Body1>;
                    })}
                  </div>
                </DialogDescription>
                <DialogClose>
                  <Button
                    variant={"negative"}
                    onClick={() => setInvalidEmailVars([])}
                  >
                    Close
                  </Button>
                </DialogClose>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col self-start w-1/2 gap-4">
            <div className="flex flex-col">
              <Headline2Variable>
                Email Variables (click to copy)
              </Headline2Variable>
              {/** Email Variables */}
              <div className="flex flex-col gap-2">
                <div className="flex flex-row gap-2 w-[360px]">
                  <Body1 className="w-32">First Name:</Body1>
                  <IconButton
                    sx={{
                      "&:hover": { color: "var(--primary-80)" },
                      height: "24px",
                      width: "24px",
                    }}
                    onClick={() => {
                      navigator.clipboard.writeText("%%first_name%%");
                    }}
                  >
                    <ContentCopyOutlinedIcon />
                  </IconButton>
                  <Body1 className="w-fit bg-info-90">%%first_name%%</Body1>
                </div>
                {[1].includes(+email.typeId) && (
                  <>
                    <div className="flex flex-row gap-2 w-[360px]">
                      <Body1 className="w-32">Team Name:</Body1>
                      <IconButton
                        sx={{
                          "&:hover": { color: "var(--primary-80)" },
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("%%team_name%%");
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <Body1 className="w-fit bg-info-90">%%team_name%%</Body1>
                    </div>
                    <div className="flex flex-row gap-2 w-[360px]">
                      <Body1 className="w-32">Day of week:</Body1>
                      <IconButton
                        sx={{
                          "&:hover": { color: "var(--primary-80)" },
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("%%day_of_week%%");
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <Body1 className="w-fit bg-info-90">
                        %%day_of_week%%
                      </Body1>
                    </div>
                    <div className="flex flex-row gap-2 w-[360px]">
                      <Body1 className="w-32">Sport:</Body1>
                      <IconButton
                        sx={{
                          "&:hover": { color: "var(--primary-80)" },
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("%%sport%%");
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <Body1 className="w-fit bg-info-90">%%sport%%</Body1>
                    </div>
                    <div className="flex flex-row gap-2 w-[360px]">
                      <Body1 className="w-32">Division Name:</Body1>
                      <IconButton
                        sx={{
                          "&:hover": { color: "var(--primary-80)" },
                          height: "24px",
                          width: "24px",
                        }}
                        onClick={() => {
                          navigator.clipboard.writeText("%%division_name%%");
                        }}
                      >
                        <ContentCopyOutlinedIcon />
                      </IconButton>
                      <Body1 className="w-fit bg-info-90">
                        %%division_name%%
                      </Body1>
                    </div>
                    <div className="flex flex-row items-center">
                      <div className="flex flex-row gap-2 min-w-[360px]">
                        <Body1 className="min-w-32 whitespace-nowrap">
                          League Rules:
                        </Body1>
                        <IconButton
                          sx={{
                            "&:hover": { color: "var(--primary-80)" },
                            height: "24px",
                            width: "24px",
                          }}
                          onClick={() => {
                            switch (leagueRuleOption) {
                              case 0: {
                                navigator.clipboard.writeText("%%ruleLink%%");
                                break;
                              }
                              case 1: {
                                navigator.clipboard.writeText(
                                  "%%ruleText_text%%"
                                );
                                break;
                              }
                              case 2:
                                {
                                  navigator.clipboard.writeText(
                                    "%%ruleButton_text%%"
                                  );
                                }
                                break;
                              default: {
                                navigator.clipboard.writeText("%%ruleLink%%");
                                break;
                              }
                            }
                          }}
                        >
                          <ContentCopyOutlinedIcon />
                        </IconButton>
                        <Body1 className="w-fit bg-info-90 h-fit">
                          {(() => {
                            switch (leagueRuleOption) {
                              case 0:
                                return "%%ruleLink%%";
                              case 1:
                                return "%%ruleText_text%%";
                              case 2:
                                return "%%ruleButton_text%%";
                              default:
                                return "%%ruleLink%%";
                            }
                          })()}
                        </Body1>
                      </div>
                      <FormFieldSelect
                        className="w-32 h-fit"
                        name="leagueRuleOption"
                        label=""
                        placeholder="Select "
                        value={leagueRuleOption?.toString() ?? "0"}
                        inputChange={(value: string) => {
                          setLeagueRuleOption(+value);
                        }}
                      >
                        {[
                          { id: "0", name: "Link" },
                          { id: "1", name: "Link with text" },
                          { id: "2", name: "Button" },
                        ]}
                      </FormFieldSelect>
                    </div>
                  </>
                )}

                <div className="flex flex-col gap-1">
                  <div className="flex flex-row gap-2 w-[360px]">
                    <Body1 className="w-32">Button:</Body1>
                    <IconButton
                      sx={{
                        "&:hover": { color: "var(--primary-80)" },
                        height: "24px",
                        width: "24px",
                      }}
                      onClick={() => {
                        navigator.clipboard.writeText("%%button_link_text%%");
                      }}
                    >
                      <ContentCopyOutlinedIcon />
                    </IconButton>
                    <Body1 className="w-fit bg-info-90">
                      %%button_link_text%%
                    </Body1>
                  </div>
                  <Body1>%%button_https://jamsports.com_Home%%</Body1>
                </div>
              </div>
            </div>
            <div className="max-w-[600px]">
              <iframe
                ref={iframeRef}
                title="Email Preview"
                style={{ width: "100%", height: "600px", border: "none" }}
              />
            </div>
          </div>
        </div>
      )}
    </main>
  );
};

export default EmailTemplateManager;
