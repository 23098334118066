import { gql } from "@apollo/client";

const GET_FREE_AGENT_REG_BY_SESSIONID = gql`
  query FreeAgentRegistrationBySessionId($sessionId: Int) {
    freeAgentRegistrationBySessionId(sessionId: $sessionId) {
      freeAgentTeams {
        name
        isIndyTeam
        id
        teamPlayers {
          id
          playerUserProfile {
            firstName
            lastName
            genderIdentity {
              name
            }
            id
          }
          teamPlayerRole {
            teamPlayerRoleName
          }
          registration {
            id
            regQuestionChoice {
              regSkillChoice {
                name
              }
              toBeCaptain {
                name
                id
              }
            }
          }
        }
      }
      freeAgentRegistrations {
        id
        user {
          firstName
          lastName
          genderIdentity {
            name
          }
          id
        }
        coPlayerRequest {
          value
          user {
            firstName
            lastName
          }
        }
        regQuestionChoice {
          regSkillChoice {
            name
          }
          toBeCaptain {
            name
            id
          }
        }
      }
    }
  }
`;

const REGISTRATION_BY_USERID = gql`
  query RegistrationByUserId($userId: String!) {
    registrationByUserId(userId: $userId) {
      id
      createdAt
      session {
        league {
          sportFormat {
            region {
              name
              id
            }
          }
          name
        }
        dayOfWeek
        registrationBatch {
          id
          name
        }
      }
      registrationType {
        id
        type
      }
      team {
        name
        id
      }
      shoppingCart {
        id
      }
      shoppingCartItem {
        id
        amountPaid
      }
    }
  }
`;

const GET_ALL_REGISTRATION_TYPES = gql`
  query RegistrationTypes {
    registrationTypes {
      id
      type
    }
  }
`;

export {
  GET_FREE_AGENT_REG_BY_SESSIONID,
  REGISTRATION_BY_USERID,
  GET_ALL_REGISTRATION_TYPES,
};
