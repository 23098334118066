import {
  createAsyncThunk,
  createSlice,
  MiddlewareArray,
  ThunkMiddleware,
  AnyAction,
} from "@reduxjs/toolkit";
import { RootState } from "./store";
import axios from "axios";
import { ToolkitStore } from "@reduxjs/toolkit/dist/configureStore";
import { client } from "../graphql";
import { Permission, User, UserAccount } from "../types/types";
import {
  CREATE_PERMISSION,
  GET_PERMISSIONS,
  GET_USER,
} from "../graphql/queries/user";
import { toast as toastStackable } from "sonner";

interface InitialStateAuth {
  isLoading: boolean;
  // user: IUser | null;
  user: User | null;
  token: string | null;
  userLocation: number | null;
  permissions: Permission[];
}

// Get user from localStorage
// const user = localStorage.getItem('user');
const token = localStorage.getItem("jwtToken");
//const userLocation = localStorage.getItem('location');

const initialState: InitialStateAuth = {
  isLoading: false,
  user: null,
  // user: user ? JSON.parse(user) : null,
  token: token || null,
  userLocation: null,
  permissions: [],
  // userLocation: userLocation ? parseInt(userLocation) : null,
};

let store: ToolkitStore<
  {
    auth: InitialStateAuth;
  },
  AnyAction,
  MiddlewareArray<
    [
      ThunkMiddleware<
        {
          auth: InitialStateAuth;
        },
        AnyAction,
        undefined
      >
    ]
  >
>;

export const injectStore = (_store: any) => {
  store = _store;
};

// adding user to local storage
const addUserToLocalStorage = ({
  // user,
  token,
}: {
  // user: IUser;
  token: string;
}): void => {
  // localStorage.setItem('user', JSON.stringify(user));
  localStorage.setItem("jwtToken", JSON.stringify(token));
};

// removing user to local storage
export const removeUserToLocalStorage = (): void => {
  // localStorage.removeItem('user');
  localStorage.removeItem("jwtToken");
};

const getjwtToken = () =>
  localStorage.getItem("jwtToken") ? localStorage.getItem("jwtToken") : null;

// baseURL
const baseURL = `${
  process.env.REACT_APP_ENV === "production"
    ? "https://admin.jamsports.com"
    : process.env.REACT_APP_ENV === "staging"
    ? "https://admin.jamitall.com"
    : "http://localhost:3001"
}/api/v1`;

export const multipartFetch = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "multipart/form-data",
  },
  withCredentials: true,
});

export const authFetch = axios.create({
  baseURL: baseURL,
  headers: {
    "Content-type": "application/json",
  },
  withCredentials: true,
});

multipartFetch.interceptors.request.use(
  (config) => {
    const jwt = getjwtToken();
    const newJWT = jwt?.slice(1, -1);
    config.headers["Authorization"] = `Bearer ${newJWT}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

authFetch.interceptors.request.use(
  (config) => {
    const jwt = getjwtToken();
    const newJWT = jwt?.slice(1, -1);
    config.headers["Authorization"] = `Bearer ${newJWT}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// const refreshToken = async () => {
//   // try {
//   const url = `${baseURL}/auth/refresh`;

//   const resp = await axios.get(url, {
//     headers: {
//       'Content-type': 'application/json',
//     },
//     withCredentials: true,
//   });

//   return resp.data;
//   // }
//   // catch (error) {
//   // }
// };

authFetch.interceptors.response.use(
  (response: any) => {
    return response;
  },
  async (error) => {
    process.env.NODE_ENV === "development" && console.log(error);
  }
  // async (error) => {

  //   const originalRequest = error.config;
  //   if (error.response.status === 403) {
  //     const resp = await refreshToken();

  //     const token = resp.accessToken as string;
  //     const user = resp.user as IUser;

  //     if (!user.isAdmin) {

  //       store.dispatch(logout());
  //       window.location.href = '/permissionDenied';
  //       return Promise.reject(error);
  //     }

  //     store.dispatch(refreshCredentials({ user, token }));

  //     addUserToLocalStorage({ user, token });

  //     // setting states in state management

  //     authFetch.defaults.headers['Authorization'] = `Bearer ${token}`;

  //     return authFetch(originalRequest);
  //   }

  //   if (error.response.status === 401) {

  //     // removeUserToLocalStorage();
  //     // store.dispatch(logout());
  //     window.location.href = '/permissionDenied';
  //     return Promise.reject(error);
  //   }

  //   window.location.href = '/permissionDenied';
  //   return Promise.reject(error);
  // }
);

// Register User
export const registerUser = createAsyncThunk(
  "auth/register",
  async (
    currentUser: User,
    // currentUser: IUser
    thunkAPI
  ) => {
    const url = `${baseURL}/auth/register`;
    try {
      const response = await axios.post(url, currentUser, {
        headers: {
          "Content-type": "application/json",
        },
        withCredentials: true,
      });
      const { user, token } = response.data;

      if (response.data) {
        addUserToLocalStorage({ token });
      }
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const verifyMFA = createAsyncThunk(
  "auth/verifyMFA",
  async (
    currentUser: {
      email: string;
      password: string;
      mfaCode: string;
      rememberMe: boolean;
    },
    thunkAPI
  ) => {
    const url = `${baseURL}/auth/verify-mfa`;

    try {
      const response = await axios.post(url, currentUser, {
        headers: {
          "Content-type": "application/json",
        },
        withCredentials: true,
      });
      const { user, token } = response.data;
      if (response.data) {
        addUserToLocalStorage({ token });
      }
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const sendMFACode = createAsyncThunk(
  "auth/sendMFACode",
  async (
    currentUser: {
      email: string;
      password: string;
      communicationMethod: string;
    },
    thunkAPI
  ) => {
    const url = `${baseURL}/auth/delivery-method`;

    try {
      const response = await axios.post(url, currentUser, {
        headers: {
          "Content-type": "application/json",
        },
        withCredentials: true,
      });
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const loginUser = createAsyncThunk(
  "auth/login",
  async (currentUser: { email: string; password: string }, thunkAPI) => {
    const url = `${baseURL}/auth`;

    try {
      const response = await axios.post(url, currentUser, {
        headers: {
          "Content-type": "application/json",
        },
        withCredentials: true,
      });
      // const { user, token } = response.data;
      // if (response.data) {
      //   addUserToLocalStorage({ token });
      // }
      return await response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

export const logoutUser = createAsyncThunk(`auth/logout`, async () => {
  const url = `${baseURL}/auth/logout`;
  await axios.post(url, {
    headers: {
      "Content-type": "application/json",
    },
    withCredentials: true,
  });
  removeUserToLocalStorage();
  window.location.href = "/login";
});

export const getAllPermissions = createAsyncThunk(
  "auth/getAllPermissions",
  async (_, thunkAPI) => {
    try {
      const response = await client.query({
        query: GET_PERMISSIONS,
        fetchPolicy: "no-cache",
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      const permissions = response.data.permissions;
      return permissions;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const createPermission = createAsyncThunk(
  "auth/createPermission",
  async (name: string, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: CREATE_PERMISSION,
        variables: {
          name: name,
        },
      });
      if (response.errors) {
        throw new Error(
          response.errors.map((error) => error.message).join(", ")
        );
      }
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getUserDetails = createAsyncThunk(
  "auth/getUserDetails",
  async (jwt: string, thunkAPI) => {
    try {
      const response = await client.mutate({
        mutation: GET_USER,
        variables: { jwt: jwt },
      });
      return response.data.getUser;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response.data.msg);
    }
  }
);

// export const refToken = createAsyncThunk(`auth/refToken`, async () => {
//   const resp = await refreshToken();
//   return await resp;
// });

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    logout: (state) => {
      state.user = null;
      state.token = null;
      state.userLocation = null;
    },
    getUser: (state, action) => {
      state.user = action.payload;
    },
    getPermissions: (state, action) => {
      state.permissions = action.payload;
    },
    // refreshCredentials: (state, action) => {
    //   state = {
    //     ...initialState,
    //     user: action.payload.user,
    //     token: action.payload.token,
    //   };
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerUser.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(registerUser.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(verifyMFA.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyMFA.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload.user;
        state.token = action.payload.token;
      })
      .addCase(verifyMFA.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(sendMFACode.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMFACode.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(sendMFACode.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(loginUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      // .addCase(refToken.pending, (state) => {
      //   state.isLoading = true;
      // })
      // .addCase(refToken.fulfilled, (state, action) => {
      //   state.isLoading = false;
      //   state.user = action.payload.user;
      //   state.token = action.payload.accessToken;
      // })
      // .addCase(refToken.rejected, (state, action) => {
      //   state.isLoading = false;
      // toastStackable.error("Cant create Permission");
      // })
      .addCase(logoutUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(logoutUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(logoutUser.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(getAllPermissions.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getAllPermissions.fulfilled, (state, action) => {
        state.isLoading = false;
        state.permissions = action.payload;
      })
      .addCase(getAllPermissions.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.payload as string);
      })
      .addCase(createPermission.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        state.isLoading = false;
        toastStackable.success(action.payload.permissionCreate.message);
      })
      .addCase(createPermission.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error("Cant create Permission");
      })
      .addCase(getUserDetails.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getUserDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.user = action.payload;
      })
      .addCase(getUserDetails.rejected, (state, action) => {
        state.isLoading = false;
        toastStackable.error(action.error.message ?? "Error fetching user");
      });
  },
});

export const authSelector = (state: RootState) => state.auth;

export const { logout, getUser, getPermissions } = authSlice.actions;

export default authSlice.reducer;
